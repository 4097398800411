import React from "react";
import { UilEllipsisV } from "@iconscout/react-unicons";

type DashboardCardProps = {
  avatar: React.ReactNode;
  description: string;
  background: string;
  counter: number | string;
  width: number;
  showProgress?: boolean;
};

const DashboardCard: React.FC<DashboardCardProps> = ({
  avatar,
  description,
  background,
  counter,
  width,
  showProgress = true,
}) => {
  return (
    <div className="col-xxl-3 col-md-6">
      <div className="card stretch stretch-full">
        <div className="card-body">
          <div className="d-flex align-items-start justify-content-between mb-4">
            <div className="d-flex gap-4 align-items-center">
              <div className="avatar-text avatar-lg bg-gray-200">{avatar}</div>
              <div>
                <div className="fs-4 fw-bold text-dark">
                  <span className="counter">{counter}</span>
                </div>
                <h3 className="fs-13 fw-semibold text-truncate-1-line">
                  {description}
                </h3>
              </div>
            </div>
            <button className="border-0 bg-transparent">
              <UilEllipsisV />
            </button>
          </div>
          <div className="pt-4">
            <div className="d-flex align-items-center justify-content-between">
              <span className="fs-12 fw-medium text-muted text-truncate-1-line">
                {description}
              </span>
              <div className="w-100 text-end">
                {/* <span className="fs-12 text-dark">$5,569</span> */}
                {showProgress && (
                  <span className="fs-11 text-muted">({width}%)</span>
                )}
              </div>
            </div>
            {showProgress && (
              <div className="progress mt-2 ht-3">
                <div
                  className={`progress-bar bg-${background}`}
                  role="progressbar"
                  style={{ width: `${width}% ` }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
