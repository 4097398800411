import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { AuthService } from "../../services/authService";

type Props = {};

const ForgotPassword = (props: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);

  const handleSubmit = async () => {
    if (submitting) return;
    setSubmitting(true);
    const email = formik.values.email;
    if (email)
      try {
        await AuthService.confirmEmail({ email })
          .then((res: any) => {
            if (res.status === -1) {
              toast.error(res.message);
              return;
            }
            toast.success("Request Sucessfull! Please check your email inbox", {
              duration: 6000,
            });
            localStorage.setItem("email", res.data.email);
            setSendRequest(true);
          })
          .catch((err) => {
            const error = err.response.data.message;
            toast.error(error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } catch (error) {
        // Handle form submission error
        toast.error("An error occured. Please try again later");
        setSubmitting(false);
      }
  };

  const formik = useFormik({
    initialValues: {
      email: null,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email").required("Email is required"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <main className="auth-minimal-wrapper">
      <div className="d-flex flex-column align-items-center gap-3 p-3">
        <img
          src="../assets/images/dark.png"
          alt="nufiSMS logo"
          style={{ height: "100px" }}
        />
        <div className="card stretch stretch-full col-md-4 mx-auto">
          <div className="card-body p-sm-5">
            {sendRequest ? (
              <div>
                <h5 className="text-success text-center">
                  Email Sent Sucessfully
                </h5>
                <h6 className="text-center">Check your inbox</h6>
                <div className="mt-5 text-muted text-center">
                  <span> Didn't receive an email?</span>
                  <span
                    className="fw-bold text-primary cursor-pointer"
                    onClick={handleSubmit}
                  >
                    {submitting && <span className="loader"></span>}
                    {submitting ? "Resending..." : "Resend"}
                  </span>
                </div>
              </div>
            ) : (
              <>
                <h3 className="text-center">Forgot Password</h3>
                <p className="fs-12 fw-medium text-muted">
                  Enter your email and a reset link will sent to you, let's
                  access our the best recommendation for you.
                </p>
                <form
                  className="w-100 mt-4 pt-2"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="mb-4">
                    <input
                      className="form-control"
                      placeholder="Email"
                      required
                      id="email"
                      type="email"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-danger">{formik.errors.email}</div>
                    ) : null}
                  </div>
                  <div className="mt-5">
                    <button
                      type="submit"
                      disabled={submitting || !formik.isValid}
                      className="btn btn-lg btn-primary w-100"
                      onClick={handleSubmit}
                    >
                      {submitting && <span className="loader"></span>}
                      Confirm
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgotPassword;
