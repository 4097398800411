import React from "react";
import { Link } from "react-router-dom";

type Props = {};

const TermsAndConditions = (props: Props) => {
  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center">
        <Link to={"/"}>
          <img
            src="../assets/images/dark.png"
            alt="Logo Large"
            style={{ height: "70px" }}
          />
        </Link>
      </div>
      <div className="mt-5">
        <h2 className="text-center mb-4">
          <strong>NufiSMS Terms of Service</strong>
        </h2>
        <p className="text-justify">
          Welcome to <strong>NufiSMS</strong>! We’ve crafted these Terms of
          Service to be clear and straightforward. Below is a brief overview,
          but we recommend you read the full Terms for complete details.
        </p>
        <p className="text-justify">
          <strong>NufiSMS</strong> is a web application designed to simplify
          your messaging needs. Whether you're sending SMS or WhatsApp messages,
          individually or in bulk, scheduling messages, or extracting contacts,
          NufiSMS provides the tools you need to manage your communications
          effectively.
        </p>
        <p className="text-justify">
          While NufiSMS facilitates these messaging services, it’s important to
          note that we don’t control third-party platforms like WhatsApp. Our
          responsibility is to ensure that the messages you send through NufiSMS
          are processed according to your instructions.
        </p>
        <p className="text-justify">
          Please continue reading for more detailed information on your rights,
          responsibilities, and the limitations of our service.
        </p>
      </div>
      <div className="mt-5">
        <h3 className="mb-4">
          <strong>About NufiSMS</strong>
        </h3>
        <p className="text-justify">
          NufiSMS is a web-based platform designed to facilitate the sending of
          messages via SMS and WhatsApp. Our platform allows you to send
          messages individually or in bulk, schedule messages for later
          delivery, and manage contacts efficiently.
        </p>
        <p className="text-justify">
          Please note that NufiSMS is solely a service provider and does not own
          or control any of the telecommunications networks or services used to
          send your messages. We act as an intermediary to help you connect with
          your recipients via the communication channels of your choice.
        </p>

        <h3 className="mt-5 mb-4">
          <strong>Changes to Terms of Service</strong>
        </h3>
        <p className="text-justify">
          We reserve the right to modify these Terms of Service at any time. Any
          changes will be effective immediately upon posting on this page, and
          will apply to your next use of the NufiSMS Platform. We encourage you
          to review the Terms of Service periodically to ensure that you
          understand the terms and conditions that apply to your use of our
          platform.
        </p>
        <p className="text-justify">
          If you do not agree with the updated Terms of Service, you must stop
          using the NufiSMS Platform. Your continued use of the platform after
          changes have been made indicates your acceptance of the updated terms.
        </p>
      </div>
      <div className="mt-5">
        <h6 className="text-justify">
          By using NufiSMS, you enter into an agreement with us under these
          Terms of Service. However, your interaction with the SMS and WhatsApp
          services is subject to the terms of service of the respective API
          providers.
        </h6>
        <p className="text-justify">
          When you make a payment for our services, NufiSMS processes
          transactions as a commercial agent on behalf of the API providers,
          discharging your obligation for the service paid. This does not apply
          to any services that require direct payment through other channels.
        </p>

        <h3 className="mt-5 mb-4">
          <strong>API Providers' Services</strong>
        </h3>
        <p className="text-justify">
          If you use NufiSMS to send messages, you also agree to the terms and
          conditions of the respective SMS and WhatsApp API providers. The
          provision of these messaging services and the fulfillment of your
          requests are subject to the terms set by these API providers. NufiSMS
          is not a party to the agreement between you and the API providers.
        </p>

        <h3 className="mt-5 mb-4">
          <strong>API Providers' Responsibilities</strong>
        </h3>
        <p className="text-justify">
          Our API providers are responsible for delivering messaging services as
          outlined in their respective terms of service. We require our API
          providers to ensure that all information related to their services is
          accurate and complete. However, NufiSMS cannot independently verify
          this information.
        </p>
        <p className="text-justify">
          Descriptions and images of the messaging services on the NufiSMS
          platform are provided for illustrative purposes only. Actual services
          provided by the API providers may differ from the descriptions and
          images shown.
        </p>
      </div>
      <div className="mt-5">
        <h3 className="mb-4">
          <strong>Using the NufiSMS Platform</strong>
        </h3>
        <p className="text-justify">
          To send messages using the NufiSMS platform, you must top up your
          account with us. For WhatsApp messaging, you will need to subscribe to
          a specific package, which grants you access to the WhatsApp API
          features. For SMS messaging, you pay based on your usage, with pricing
          varying according to factors such as destination country and phone
          number operator.
        </p>

        <h3 className="mt-5 mb-4">
          <strong>Creating and Managing Your NufiSMS Account</strong>
        </h3>
        <p className="text-justify">
          To use NufiSMS services, you may choose to create an account with us
          by signing up and providing essential information such as your name,
          mobile telephone number, and company name if you are a company.
        </p>
        <p className="text-justify">
          It is crucial to provide accurate and complete information when
          creating and managing your account. You are responsible for keeping
          your information up to date through the NufiSMS platform. We may
          verify the information you provide and reserve the right to refuse
          access to our services if the information is inaccurate or incomplete.
        </p>
      </div>
      <div className="mt-5">
        <h3 className="mb-4">
          <strong>How to Send a Message Using NufiSMS</strong>
        </h3>

        <p className="text-justify">
          NufiSMS allows you to send messages via SMS or WhatsApp. Below are the
          steps for each messaging option:
        </p>

        <h4 className="mt-4 mb-3">
          <strong>Sending an SMS</strong>
        </h4>
        <p className="text-justify">
          To send an SMS, ensure you have sufficient funds in your NufiSMS
          account. Each SMS will be billed based on the phone number operator
          and destination country. You can view the detailed pricing list on our
          website.
        </p>
        <p className="text-justify">
          Before sending an SMS, check your balance and top up if needed. You
          can then compose your message, specify the recipient's phone number,
          and choose to schedule the message for future delivery if desired. You
          also have the option to create and manage SMS campaigns for targeted
          messaging.
        </p>

        <h4 className="mt-4 mb-3">
          <strong>Sending a WhatsApp Message</strong>
        </h4>
        <p className="text-justify">
          For WhatsApp messaging, you need to subscribe to a WhatsApp package
          that suits your needs. After subscribing, connect your WhatsApp
          account using your WhatsApp phone number.
        </p>
        <p className="text-justify">
          With NufiSMS, you can also schedule WhatsApp messages for future
          delivery and manage WhatsApp campaigns for bulk messaging. These
          features help streamline your communication strategy and ensure timely
          delivery of your messages.
        </p>

        <h4 className="mt-4 mb-3">
          <strong>Demo Videos</strong>
        </h4>
        <p className="text-justify">
          To help you get started, we have provided demo videos that explain how
          to send SMS and WhatsApp messages, schedule messages, and create
          campaigns. You can watch these videos for a detailed walkthrough:
        </p>
        <ul>
          <li>
            <a href="https://youtube.com/example_sms_demo">
              Watch SMS Sending Demo
            </a>
          </li>
          <li>
            <a href="https://youtube.com/example_whatsapp_demo">
              Watch WhatsApp Sending Demo
            </a>
          </li>
          <li>
            <a href="https://youtube.com/example_schedule_sms_demo">
              Watch SMS Scheduling Demo
            </a>
          </li>
          <li>
            <a href="https://youtube.com/example_schedule_whatsapp_demo">
              Watch WhatsApp Scheduling Demo
            </a>
          </li>
          <li>
            <a href="https://youtube.com/example_campaigns_demo">
              Watch Campaigns Demo
            </a>
          </li>
        </ul>
      </div>
      <div className="mt-5">
        <h3 className="mb-4">
          <strong>Ending These Terms of Service</strong>
        </h3>
        <p className="text-justify">
          Upon termination of your use of NufiSMS, we will make reasonable
          efforts to delete or return all personal data within thirty (30) days
          of the termination, as directed by you.
        </p>
        <p className="text-justify">
          However, NufiSMS may retain personal data beyond the termination if
          required to do so in order to comply with applicable laws.
        </p>

        <h3 className="mt-5 mb-4">
          <strong>Updating the Service and These Terms of Service</strong>
        </h3>
        <p className="text-justify">
          These Terms of Service were last updated on 16 Aug 2024. We may update
          these Terms of Service periodically and will post any changes on this
          page. NufiSMS may also modify or replace any part of these Terms, or
          change, suspend, or discontinue any aspect of the NufiSMS Services,
          including the availability of any features or content, at any time.
        </p>
        <p className="text-justify">
          We will notify you of any significant changes by posting a notice on
          the NufiSMS Platform or by sending you notifications through your
          NufiSMS Account or via email. Continued use of the NufiSMS Platform
          after any such changes indicates your acceptance of the new terms.
        </p>
        <p className="text-justify">
          While using the NufiSMS Platform, we may also impose limits on certain
          features or restrict access to parts or all of the NufiSMS Services
          without prior notice or liability. Such changes will not affect any
          actions or transactions completed prior to the changes.
        </p>

        <h3 className="mt-5 mb-4">
          <strong>Need Help with Anything?</strong>
        </h3>
        <p className="text-justify">
          If you have any questions or need assistance, please contact our
          support team. We are here to help you with any issues or concerns you
          may have regarding our services.
        </p>
      </div>
      <div className="mt-5 d-flex flex-column flex-md-row justify-content-between pt-2 border-top">
        <p className="text-center">
          Crafted with ❤ by{" "}
          <Link
            to={"https://digitwace.com/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            DigitWace Cameroun Sarl
          </Link>
        </p>
        <p className="text-center">
          © Copyright 2024 NufiSMS. All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
