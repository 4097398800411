import React, { useCallback, useEffect, useState } from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import {
  UilWhatsapp,
  UilSetting,
  UilEllipsisV,
  UilComment,
  UilUsersAlt,
  UilArrowLeft,
} from "@iconscout/react-unicons";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import toast from "react-hot-toast";
import { whatsappService } from "../../services/whatsappService";
import WhatsappMessage from "../../components/WhatsappMssage";
import ExtractContacts from "../../components/ExtractContacts";
import { Socket } from "socket.io-client";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

type WhatsappPageProps = {
  socket: Socket;
};

const WhatsappPage: React.FC<WhatsappPageProps> = ({ socket }) => {
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [base64Url, setBase64Url] = useState<string | undefined>();
  const [existingSession, setExistingSession] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSessionInit, setSessionInit] = useState<boolean>(false);
  const [isScanning, setIsScanning] = useState(false); // Track loading state after QR is scanned
  const [view, setView] = useState<"main" | "whatsapp" | "contacts">("main");

  const { currentUser, loading } = useSelector(
    (state: RootState) => state.auth
  );

  const hasActiveSubscription = useCallback(
    () =>
      currentUser?.activeSubscriptions &&
      currentUser.activeSubscriptions.length > 0,
    [currentUser]
  );

  const handleInitializeSession = useCallback(async () => {
    if (!phoneNumber) {
      toast.error("Please enter a phone number.");
      return;
    }

    const formattedPhoneNumber = phoneNumber.startsWith("+")
      ? phoneNumber.slice(1)
      : phoneNumber;

    try {
      setIsLoading(true);
      const response = await whatsappService.initSession(formattedPhoneNumber);

      if (response.status === -1) {
        toast.error(response.message);
      } else if (response.data) {
        setBase64Url(response.data);
        console.log(base64Url);
        toast.success("Session initialized successfully!");
        setCurrentStep(1);
        setExistingSession(false);
      } else {
        toast.success("You already have an active session!");
        setExistingSession(true);
        setSessionInit(true);
      }
    } catch (error) {
      toast.error("An error occurred during session initialization.");
    } finally {
      setIsLoading(false);
    }
  }, [base64Url, phoneNumber]);

  const handleBackToMain = () => setView("main");

  const handleNavigate = (action: "whatsapp" | "contacts") => {
    setView(action);
  };

  const handleBackStep = () => {
    setCurrentStep(0);
    setSessionInit(false);
  };

  useEffect(() => {
    // Connect the socket manually
    socket.connect();

    // Define the event listener
    const handleWhatsappStatus = (data: {
      message: string;
      phoneNumber: string;
    }) => {
      console.log(data);

      if (data.message === "Session status: qrReadSuccess") {
        // When QR code is successfully read, show the loader
        setIsScanning(true);
      }

      if (data.message === "Session status: inChat") {
        // When session is in chat, remove the loader
        setIsScanning(false);
        setSessionInit(true);
        // Perform any additional actions needed, such as navigating to another page
        console.log("WhatsApp session is now in chat.");
      }
    };

    // Set up the listener
    socket.on("whatsappStatus", handleWhatsappStatus);

    // Clean up the listener on component unmount
    return () => {
      socket.disconnect();
      socket.off("whatsappStatus", handleWhatsappStatus);
    };
  }, [socket]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, [socket]);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container apps-container apps-email">
          <div className="nxl-content">
            <div className="page-header">
              {view !== "main" && (
                <button
                  className="btn btn-light-brand p-1 px-3 me-3"
                  onClick={handleBackToMain}
                >
                  <UilArrowLeft />
                  Back to menu
                </button>
              )}
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">
                    <UilWhatsapp /> WhatsApp Integration
                  </h5>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div
                className="content-area"
                data-scrollbar-target="#psScrollbarInit"
              >
                {loading ? (
                  <div className="col-md-8 mx-auto">
                    <Stack spacing={1}>
                      {/* For variant="text", adjust the height via font-size */}
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

                      {/* For other variants, adjust the size with `width` and `height` */}
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton
                        variant="rectangular"
                        className="w-100"
                        height={60}
                      />
                      <Skeleton
                        variant="rounded"
                        className="w-100"
                        height={60}
                      />
                    </Stack>
                  </div>
                ) : hasActiveSubscription() ? (
                  <div className="mx-auto d-flex flex-column justify-content-center align-items-center">
                    <h3>Welcome!</h3>
                    <p className="mb-1 text-black fs-14">
                      It looks like you don't have an active subscription at the
                      moment.
                    </p>
                    <p className="fs-14 mb-0">
                      To start sending messages via WhatsApp, please choose a
                      subscription plan that best suits your needs.
                    </p>
                    <Link to="/subscribe" className="btn btn-primary mt-3">
                      Subscribe Now
                    </Link>
                  </div>
                ) : (
                  <div>
                    {view === "main" && !isSessionInit && (
                      <div className="card mx-auto mt-3">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="col-md-6">
                              <h5 className="text-muted">
                                Setup WhatsApp Integration
                              </h5>
                              <ul className="list-unstyled mt-3 activity-feed">
                                <li className="feed-item feed-item-danger">
                                  Enter your WhatsApp phone number and click{" "}
                                  <strong>Next</strong>
                                </li>
                                <li className="feed-item feed-item-warning">
                                  Open WhatsApp on your phone
                                </li>
                                <li className="feed-item feed-item-primary">
                                  Tap Menu <UilEllipsisV /> or Settings{" "}
                                  <UilSetting /> and select{" "}
                                  <strong>Linked Devices</strong>
                                </li>
                                <li className="feed-item feed-item-info">
                                  Tap on <strong>Link a Device</strong>
                                </li>
                                <li className="feed-item feed-item-success">
                                  Point your phone to this screen to capture the
                                  QR code
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6">
                              {currentStep === 0 && (
                                <div>
                                  <h5>Enter Sender Phone Number:</h5>
                                  <PhoneInput
                                    placeholder="Enter phone number"
                                    international
                                    defaultCountry="CM"
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                    className="form-control"
                                  />
                                  <button
                                    disabled={isLoading || !phoneNumber}
                                    className="btn btn-primary mt-3 mx-auto"
                                    onClick={handleInitializeSession}
                                  >
                                    {isLoading && (
                                      <span className="loader"></span>
                                    )}{" "}
                                    Next
                                  </button>
                                </div>
                              )}
                              {currentStep === 1 && !existingSession && (
                                <div className="step-content d-flex flex-column justify-content-center align-items-center gap-3 my-2">
                                  <div>
                                    <h6>
                                      Scan the QR Code to Connect to WhatsApp:
                                    </h6>
                                    <p className="text-center">
                                      This QR code will expire after 45 seconds
                                    </p>
                                  </div>
                                  <div className="qr-placeholder">
                                    {isScanning && (
                                      <div className="qr-overlay">
                                        <div className="qr-spinner"></div>
                                        <div className="qr-text">
                                          Processing...
                                        </div>
                                      </div>
                                    )}
                                    {base64Url ? (
                                      <img
                                        src={base64Url}
                                        alt="Scan this QR code with WhatsApp"
                                        style={{
                                          opacity: isScanning ? 0.5 : 1,
                                        }} // Optional: Dim the QR code when scanning
                                      />
                                    ) : (
                                      "QR Code Not Available"
                                    )}
                                  </div>

                                  <div className="d-flex mx-auto gap-2">
                                    <button
                                      onClick={handleBackStep}
                                      className="btn btn-light-brand"
                                    >
                                      Back
                                    </button>
                                    {/* <button
                                      onClick={() => setSessionInit(true)}
                                      className="btn btn-light-brand"
                                    >
                                      Next
                                    </button> */}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {isSessionInit && view === "main" && (
                      <div className="row justify-content-center">
                        <div className="col-md-4">
                          <div className="card stretch stretch-full">
                            <div className="card-body d-flex flex-column">
                              <UilComment className="text-black mx-auto" />
                              <span className="text-center text-black my-1 fs-14">
                                Send WhatsApp Messages
                              </span>
                              <button
                                className="btn btn-primary mt-auto"
                                onClick={() => handleNavigate("whatsapp")}
                              >
                                Send Message
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="card stretch stretch-full">
                            <div className="card-body d-flex flex-column">
                              <UilUsersAlt className="text-black mx-auto" />
                              <span className="text-center text-black my-1 fs-14">
                                Extract Contacts from WhatsApp
                              </span>
                              <button
                                className="btn btn-primary mt-auto"
                                onClick={() => handleNavigate("contacts")}
                              >
                                Extract Contacts
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {view === "whatsapp" && (
                      <div className="d-flex flex-column">
                        <WhatsappMessage phoneNumber={phoneNumber} />
                      </div>
                    )}
                    {view === "contacts" && (
                      <div className="d-flex flex-column">
                        <ExtractContacts phoneNumber={phoneNumber} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default WhatsappPage;
