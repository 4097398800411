import React, { useEffect, useState } from "react";
import { UilSave } from "@iconscout/react-unicons";
import Select from "react-select";
import { useFormik } from "formik";
import {
  pricingInitialValues,
  pricingValidationSchema,
} from "../../validation/FormikValues";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { pricingService } from "../../services/pricingService";
import { Country } from "../../models/Coverage";
import toast from "react-hot-toast";
import makeAnimated from "react-select/animated";

type Props = {};

interface OptionType {
  value: string;
  label: string;
}

const AddPricing = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const animatedComponents = makeAnimated();

  const fetchCountries = async () => {
    try {
      setLoading(true);
      const res = await pricingService.getCountries();
      if (res.status !== -1) {
        setCountries(res.data);
      }
    } catch (error) {
      toast.error(
        "An error occurred while fetching countries. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const transformCountriesToOptions = (countries: Country[]): OptionType[] => {
    return countries.map((country) => ({
      value: country.id,
      label: country.name,
    }));
  };

  const options: OptionType[] = transformCountriesToOptions(countries);

  const calculateResellerPrice = (partnerPrice: number, percentage: number) => {
    return partnerPrice + (partnerPrice * percentage) / 100;
  };

  const handleAddPricing = async (values: typeof pricingInitialValues) => {
    try {
      setLoading(true);
      const payload = {
        name: values.operatorName.toUpperCase(),
        countryId: values.countryId,
        pricing: {
          price_partner: values.pricingPartner,
          price_reseller: calculateResellerPrice(
            values.pricingPartner,
            values.percentageReseller
          ),
          percentage: values.percentageReseller,
        },
      };
      const response = await pricingService.createPricing(payload);
      if (response.status === -1) {
        toast.error(response.message);
        return;
      }

      toast.success("Pricing added successfully!");
      formik.resetForm();
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: pricingInitialValues,
    validationSchema: pricingValidationSchema,
    onSubmit: handleAddPricing,
  });

  useEffect(() => {
    const resellerPrice = calculateResellerPrice(
      formik.values.pricingPartner,
      formik.values.percentageReseller
    );
    formik.setFieldValue("pricingReseller", resellerPrice);
  }, [formik.values.pricingPartner, formik.values.percentageReseller]);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <div className="d-flex flex-column">
                    <h5 className="m-b-10 border-0 mb-1">Add Pricing</h5>
                    <span className="fs-12 fw-normal text-muted text-truncate-1-line">
                      Please fill in the information carefully
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card stretch stretch-full">
                    <div className="card-body">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                          <div className="mb-3 col-md-4">
                            <label htmlFor="countryId">Country</label>
                            <Select<OptionType>
                              components={animatedComponents}
                              options={options}
                              className="form-control border-0 p-0 text-dark"
                              placeholder="Country"
                              id="countryId"
                              name="countryId"
                              value={
                                options.find(
                                  (option) =>
                                    option.value === formik.values.countryId
                                ) || null
                              }
                              onChange={(selectedOption) => {
                                formik.setFieldValue(
                                  "countryId",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.countryId &&
                            formik.errors.countryId ? (
                              <div className="text-danger">
                                {formik.errors.countryId}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-4">
                            <label htmlFor="pricingPartner">
                              Pricing Partner (EUR)
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Pricing Partner"
                              id="pricingPartner"
                              {...formik.getFieldProps("pricingPartner")}
                            />
                            {formik.touched.pricingPartner &&
                            formik.errors.pricingPartner ? (
                              <div className="text-danger">
                                {formik.errors.pricingPartner}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-4">
                            <label htmlFor="percentageReseller">
                              Percentage Reseller
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Percentage Reseller"
                              id="percentageReseller"
                              {...formik.getFieldProps("percentageReseller")}
                            />
                            {formik.touched.percentageReseller &&
                            formik.errors.percentageReseller ? (
                              <div className="text-danger">
                                {formik.errors.percentageReseller}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-4">
                            <label htmlFor="pricingReseller">
                              Pricing Reseller (EUR)
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Pricing Reseller"
                              id="pricingReseller"
                              value={formik.values.pricingReseller}
                              readOnly
                            />
                          </div>
                          <div className="mb-3 col-md-4">
                            <label htmlFor="operatorName">Operator Name</label>
                            <input
                              type="text"
                              className="form-control text-uppercase"
                              placeholder="Operator Name"
                              id="operatorName"
                              {...formik.getFieldProps("operatorName")}
                            />
                            {formik.touched.operatorName &&
                            formik.errors.operatorName ? (
                              <div className="text-danger">
                                {formik.errors.operatorName}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            type="submit"
                            disabled={loading || !formik.isValid}
                            className="btn btn-primary"
                          >
                            {loading && <span className="loader"></span>}
                            <UilSave /> <span className="ms-1">Save</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default AddPricing;
