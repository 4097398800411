import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  UilAngleLeftB,
  UilPaperclip,
  UilSetting,
  UilTrashAlt,
  UilPlus,
} from "@iconscout/react-unicons";
import toast from "react-hot-toast";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { User } from "../../models/User";
import { adminServices } from "../../services/adminService";

type Props = {};

const AllAdmins = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const fetchAdmins = async () => {
    try {
      setLoading(true);
      await adminServices
        .getAdminList()
        .then((res: any) => {
          if (res.status === -1) {
            return;
          }
          console.log(res.data);
          setUsers(res.data);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error(
        "An error occured while fetching data. Please try again later."
      );
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);
  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">Admin List</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>Back</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
                    <div className="dropdown">
                      <div
                        className="btn btn-icon btn-light-brand"
                        data-bs-toggle="dropdown"
                        data-bs-offset="0, 10"
                        data-bs-auto-close="outside"
                      >
                        <UilPaperclip />
                      </div>
                      <div className="dropdown-menu dropdown-menu-end">
                        <a href="javascript:void(0);" className="dropdown-item">
                          <i className="bi bi-filetype-pdf me-3"></i>
                          <span>PDF</span>
                        </a>
                        <a href="javascript:void(0);" className="dropdown-item">
                          <i className="bi bi-filetype-csv me-3"></i>
                          <span>CSV</span>
                        </a>
                        <a href="javascript:void(0);" className="dropdown-item">
                          <i className="bi bi-filetype-xml me-3"></i>
                          <span>XML</span>
                        </a>
                        <a href="javascript:void(0);" className="dropdown-item">
                          <i className="bi bi-filetype-txt me-3"></i>
                          <span>Text</span>
                        </a>
                        <a href="javascript:void(0);" className="dropdown-item">
                          <i className="bi bi-filetype-exe me-3"></i>
                          <span>Excel</span>
                        </a>
                        <div className="dropdown-divider"></div>
                        <a href="javascript:void(0);" className="dropdown-item">
                          <i className="bi bi-printer me-3"></i>
                          <span>Print</span>
                        </a>
                      </div>
                    </div>
                    <Link className="btn btn-primary" to={"/manage-roles"}>
                      <UilPlus />
                      <span>Manage Roles</span>
                    </Link>
                  </div>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <a
                    href="javascript:void(0)"
                    className="page-header-right-open-toggle"
                  >
                    <i className="feather-align-right fs-20"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card stretch stretch-full">
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table table-hover" id="customerList">
                          <thead>
                            <tr>
                              <th>Firstname</th>
                              <th>Lastname</th>
                              <th>Email</th>
                              <th>Date Created</th>
                              {/* <th>Credit sms</th> */}
                              {/* <th>use api</th> */}
                              {/* <th>Status</th> */}
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users &&
                              users.map((user, index) => (
                                <tr key={index} className="single-item">
                                  <td>
                                    <span className="text-truncate-1-line">
                                      {user.firstname}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 text-black">
                                      <div>
                                        <span className="text-truncate-1-line">
                                          {user.lastname}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{user.email}</td>
                                  <td>
                                    <div className="hstack gap-3 text-black">
                                      {moment(user.updatedAt).format(
                                        "DD/MM/YYYY HH:mm"
                                      )}
                                    </div>
                                  </td>
                                  {/* <td>{user.creditSms}</td> */}
                                  {/* <td>{user.date}</td> */}
                                  {/* <td>
                                    <span
                                      className={`badge ${getStatusBadgeClass(
                                        user.status
                                      )}`}
                                    >
                                      {user.status}
                                    </span>
                                  </td> */}
                                  <td>
                                    <div className="d-flex gap-3 align-items-center">
                                      <button
                                        className="btn btn-primary btn-light-brand py-1"
                                        // onClick={() => handleManage(user)}
                                      >
                                        <UilSetting />
                                        <span>Manage</span>
                                      </button>
                                      {/* <button
                                        className="btn btn-danger btn-light-brand py-1"
                                        onClick={() => setSelectedClient(user)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteModal"
                                      >
                                        <UilTrashAlt />
                                        <span>Delete</span>
                                      </button> */}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {users?.length === 0 && (
                          <h5 className="text-center my-5">
                            Your client list will appear here
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default AllAdmins;
