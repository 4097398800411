import React from "react";
import { User } from "../../models/User";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPackages,
  subscribePackage,
} from "../../redux/slices/packageSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { Package } from "../../models/Package";

type BillingPlanProps = {
  user: User | null;
};

const BillingPlan: React.FC<BillingPlanProps> = ({ user }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { packages, loading, error } = useSelector(
    (state: RootState) => state.packages
  );
  return (
    <div className="content-area" data-scrollbar-target="#psScrollbarInit">
      <div className="content-area-body">
        {/* <h5 className="fs-20 fw-bolder my-4 text-center">Change Password</h5> */}
        <div className="card">
          <div className="card-body">
            <div className="subscription-plan px-4 pt-4">
              <div className="mb-4 d-flex align-items-center justify-content-between">
                <h5 className="fw-bold mb-0">Whatsapp Subscription & Plan:</h5>
                <button className="btn btn-sm btn-light-brand">
                  {user?.activeSubscriptions &&
                    user?.activeSubscriptions[1].totalRemainingMessages}
                  {""} More messages to send
                </button>
              </div>
              <div className="p-4 mb-4 d-xxl-flex d-xl-block d-md-flex align-items-center justify-content-between gap-4 border border-dashed border-gray-5 rounded-1">
                <div>
                  <div className="fs-14 fw-bold text-dark mb-1">
                    Your current plan is{" "}
                    <span className="badge bg-primary text-white ms-2">
                      {user?.activeSubscriptions &&
                        user?.activeSubscriptions[1].packageName}{" "}
                      plan
                    </span>
                  </div>
                  <div className="fs-12 text-muted">
                    A simple start for everyone
                  </div>
                </div>
                <div className="my-3 my-xxl-0 my-md-3 my-md-0">
                  <div className="fs-20 text-dark">
                    <span className="fw-bold">$29.99</span>
                    {/* /{" "}
                    <em className="fs-11 fw-medium">Month</em> */}
                  </div>
                  {/* <div className="fs-12 text-muted mt-1">
                    Billed Monthly / Next payment on 12/10/2023 for{" "}
                    <strong className="text-dark">$62.48</strong>
                  </div> */}
                </div>
                <div className="hstack gap-3">
                  {/* <a href="javascript:void(0);" className="text-danger">
                    Cancel Plan
                  </a> */}
                  <a href="subscribe" className="btn btn-light-brand">
                    Update Plan
                  </a>
                </div>
              </div>
              {/* <div className="row">
                {packages?.map((pkg, index) => (
                  <div key={pkg.id} className="col-12 col-md-4 ">
                    <div
                      className={`p-4 mb-4 d-block ${
                        index % 2 === 0 ? "bg-soft-100" : "bg-soft-200"
                      } border border-dashed border-gray-5 rounded-1 cursor-pointer`}
                    >
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <h6 className="fs-13 fw-bold">
                          {pkg.name.toUpperCase()}
                        </h6>
                      </div>
                      <p className="fs-12 fw-normal text-primary">
                        Total Messages: <strong>{pkg.totalMessages}</strong>
                      </p>

                      <p className="fs-12 fw-normal text-muted text-truncate-2-line">
                        {pkg.description}
                      </p>
                      <div className="mt-4">
                        <span className="fs-20 fw-bold text-dark">
                          €{pkg.price}
                        </span>{" "}
                        / <em className="fs-11 fw-medium">Month</em>
                      </div>
                      <button
                        className="btn btn-primary w-100 mt-3"
                        data-bs-toggle="modal"
                        data-bs-target="#subscribeModal"
                        // onClick={() => handleSelectPackage(pkg)}
                      >
                        Try it now
                      </button>
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingPlan;
