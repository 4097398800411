import React, { useEffect, useState } from "react";
import {
  UilLock,
  UilPlus,
  UilAngleLeftB,
  UilCheckCircle,
  UilMoneyInsert,
  UilUserTimes,
} from "@iconscout/react-unicons";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import Billing from "../../components/clientDetails/Billing";
import Profile from "../../components/clientDetails/Profile";
import SmsTransactions from "../../components/clientDetails/SmsTransactions";
import { Client } from "../../models/Client";
import { adminServices } from "../../services/adminService";

type Props = {};

const ClientDetails = (props: Props) => {
  const [isloading, setIsloading] = React.useState(false);
  const [isActive, setActive] = useState(true);
  const [clientData, setClientData] = React.useState<Client>();
  const [clientEmail, setClientEmail] = useState<string | null>(null);
  const navigate = useNavigate();
  const { userCode } = useParams();

  const firstLetter =
    clientData && clientData.firstname ? clientData.firstname.charAt(0) : "";

  const handleResetPassword = async () => {
    try {
      if (clientData && clientData.email) {
        setIsloading(true);
        await adminServices
          .resetClientPassword({ email: clientData.email })
          .then((res: any) => {
            if (res.status === -1) {
              toast.error(res.message);
              return;
            }
            toast.success(res.message);
            setClientEmail(null);
          })
          .catch((err: any) => {
            console.log(err);
            toast.error(
              "Can not reset client password. Please try again later"
            );
          })
          .finally(() => {
            setIsloading(false);
          });
      }
    } catch (error) {
      toast.error(
        "An error occured while fetching clients. Please try again later."
      );
    }
  };
  const handleDesactivate = async () => {
    try {
      if (clientData && clientData.user_code) {
        setIsloading(true);
        await adminServices
          .deasctivateAccount(clientData.user_code)
          .then((res: any) => {
            if (res.status === -1) {
              toast.error(res.message);
              return;
            }
            toast.success(res.message);
            fetchClient();
          })
          .catch((err: any) => {
            console.log(err);
            toast.error(
              "Can not deactivate client account. Please try again later"
            );
          })
          .finally(() => {
            setIsloading(false);
          });
      }
    } catch (error) {
      toast.error(
        "An error occured while fetching clients. Please try again later."
      );
    }
  };
  const handleUpdateBalance = () => {
    navigate(`/client-details/${clientData?.user_code}/balance`);
  };

  const fetchClient = async () => {
    try {
      if (userCode) {
        setIsloading(true);
        await adminServices
          .getClientByUserCode(userCode)
          .then((res: any) => {
            if (res.status === -1) {
              return;
            }
            setClientData(res.data);
            if (res.data.status === "Account_desactivated") {
              setActive(false);
            }
          })
          .catch((err: any) => {})
          .finally(() => {
            setIsloading(false);
          });
      }
    } catch (error) {
      toast.error(
        "An error occured while fetching clients. Please try again later."
      );
    }
  };

  useEffect(() => {
    fetchClient();
  }, []);
  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">Client Details</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>Back</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
                    <a className="btn btn-primary" href="/client-create">
                      <UilPlus />
                      <span>Create Client</span>
                    </a>
                  </div>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <a
                    href="javascript:void(0)"
                    className="page-header-right-open-toggle"
                  >
                    <i className="feather-align-right fs-20"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <div className="col-xxl-4 col-xl-6  col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-4 text-center">
                        <div className="wd-100 ht-100 mx-auto border border-gray-2 rounded-circle bg-primary d-flex justify-content-center align-items-center">
                          <h5 className="text-center text-white ">
                            {firstLetter}
                          </h5>
                          <div
                            className="wd-10 ht-10 text-success rounded-circle position-absolute translate-middle"
                            style={{ top: "76%", right: "10px" }}
                          >
                            <i className="bi bi-patch-check-fill"></i>
                          </div>
                        </div>
                        <div className="mb-4">
                          <div className="fs-14 fw-bold d-block">
                            {clientData?.firstname} {clientData?.lastname}
                          </div>
                          <div className="fs-12 fw-normal text-muted d-block">
                            {clientData?.email}
                          </div>
                        </div>
                        <div className="fs-12 fw-normal text-muted text-center d-flex flex-wrap gap-3 mb-4">
                          <div className="flex-fill py-3 px-4 rounded-1 d-none d-sm-block border border-dashed border-gray-5">
                            <h6 className="fs-15 fw-bolder">
                              {clientData?.sentMessages
                                ? `${clientData.sentMessages.length}`
                                : "0"}
                            </h6>
                            <p className="fs-12 text-muted mb-0">
                              Total SMS Sent
                            </p>
                          </div>
                          <div className="flex-fill py-3 px-4 rounded-1 d-none d-sm-block border border-dashed border-gray-5">
                            <h6 className="fs-15 fw-bolder">$2000</h6>
                            <p className="fs-12 text-muted mb-0">SMS Balance</p>
                          </div>
                          <div className="flex-fill py-3 px-4 rounded-1 d-none d-sm-block border border-dashed border-gray-5">
                            <button
                              onClick={handleUpdateBalance}
                              className="btn btn-primary btn-light-brand mb-1 p-0 py-2 w-100"
                            >
                              <UilMoneyInsert />
                              Update Balance
                            </button>
                            <button
                              data-bs-toggle="modal"
                              data-bs-target="#passwordModal"
                              className="btn btn-primary btn-light-brand p-0 py-1 w-100 mb-1"
                            >
                              <UilLock />
                              <span className="">Reset Password</span>
                            </button>
                            {isActive ? (
                              <button
                                data-bs-toggle="modal"
                                data-bs-target="#deleteModal"
                                className="btn btn-danger btn-light-brand p-0 py-1 w-100"
                              >
                                <UilUserTimes />
                                <span className="">Deactivate Account</span>
                              </button>
                            ) : (
                              <button className="btn btn-success btn-light-brand p-0 py-1 w-100">
                                <UilCheckCircle />
                                <span className="">Activate Account</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Invoices</h5>
                    </div>
                    <div className="card-body"></div>
                    <div
                      className="ladda-button zoom-out"
                      data-bs-toggle="tooltip"
                      data-bs-trigger="hover"
                      title="Refresh Now"
                    >
                      <span>Refresh</span>
                      <span className="spinner"></span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-8 col-xl-6 col-md-8">
                  <div className="card border-top-0">
                    <div className="card-header p-0">
                      {/* <!-- Nav tabs --> */}
                      <ul
                        className="nav nav-tabs flex-wrap w-100 text-center customers-nav-tabs"
                        id="myTab"
                        role="tablist"
                      >
                        <li
                          className="nav-item flex-fill border-top"
                          role="presentation"
                        >
                          <div
                            className="nav-link active cursor-pointer"
                            data-bs-toggle="tab"
                            data-bs-target="#overviewTab"
                            role="tab"
                          >
                            Client Profile
                          </div>
                        </li>
                        <li
                          className="nav-item flex-fill border-top"
                          role="presentation"
                        >
                          <div
                            className="nav-link cursor-pointer"
                            data-bs-toggle="tab"
                            data-bs-target="#billingTab"
                            role="tab"
                          >
                            Billing
                          </div>
                        </li>
                        {/* <li
                          className="nav-item flex-fill border-top"
                          role="presentation"
                        >
                          <div
                            className="nav-link cursor-pointer"
                            data-bs-toggle="tab"
                            data-bs-target="#smsTab"
                            role="tab"
                          >
                            SMS Transaction
                          </div>
                        </li> */}
                        <li
                          className="nav-item flex-fill border-top"
                          role="presentation"
                        >
                          <div
                            className="nav-link cursor-pointer"
                            data-bs-toggle="tab"
                            data-bs-target="#activityTab"
                            role="tab"
                          >
                            Support Tickets
                          </div>
                        </li>
                        <li
                          className="nav-item flex-fill border-top"
                          role="presentation"
                        >
                          <div
                            className="nav-link cursor-pointer"
                            data-bs-toggle="tab"
                            data-bs-target="#notificationsTab"
                            role="tab"
                          >
                            Payment Status
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content">
                      <Profile />
                      <Billing />
                      <SmsTransactions clientData={clientData} />
                      <div
                        className="tab-pane fade"
                        id="activityTab"
                        role="tabpanel"
                      >
                        <div className="recent-activity p-4 pb-0">
                          <div className="mb-4 pb-2 d-flex justify-content-between">
                            <h5 className="fw-bold">Recent Activity:</h5>
                            <button className="btn btn-sm btn-light-brand">
                              View Alls
                            </button>
                          </div>
                          <ul className="list-unstyled activity-feed">
                            <li className="d-flex justify-content-between feed-item feed-item-success">
                              <div>
                                <span className="text-truncate-1-line lead_date">
                                  Reynante placed new order{" "}
                                  <span className="date">[April 19, 2023]</span>
                                </span>
                                <span className="text">
                                  New order placed{" "}
                                  <a
                                    href="javascript:void(0);"
                                    className="fw-bold text-primary"
                                  >
                                    #456987
                                  </a>
                                </span>
                              </div>
                              <div className="ms-3 d-flex gap-2 align-items-center">
                                <a
                                  href="javascript:void(0);"
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="Make Read"
                                >
                                  <i className="feather feather-check fs-12"></i>
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="View Activity"
                                >
                                  <i className="feather feather-eye fs-12"></i>
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="More Options"
                                >
                                  <i className="feather feather-more-vertical"></i>
                                </a>
                              </div>
                            </li>
                            <li className="d-flex justify-content-between feed-item feed-item-info">
                              <div>
                                <span className="text-truncate-1-line lead_date">
                                  5+ friends join this group{" "}
                                  <span className="date">[April 20, 2023]</span>
                                </span>
                                <span className="text">
                                  Joined the group{" "}
                                  <a
                                    href="javascript:void(0);"
                                    className="fw-bold text-primary"
                                  >
                                    "NEXEL"
                                  </a>
                                </span>
                              </div>
                              <div className="ms-3 d-flex gap-2 align-items-center">
                                <a
                                  href="javascript:void(0);"
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="Make Read"
                                >
                                  <i className="feather feather-check fs-12"></i>
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="View Activity"
                                >
                                  <i className="feather feather-eye fs-12"></i>
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="More Options"
                                >
                                  <i className="feather feather-more-vertical"></i>
                                </a>
                              </div>
                            </li>
                            <li className="d-flex justify-content-between feed-item feed-item-primary">
                              <div>
                                <span className="text-truncate-1-line lead_date">
                                  New event are coming soon{" "}
                                  <span className="date">[April 23, 2023]</span>
                                </span>
                                <span className="text">
                                  Attending the event{" "}
                                  <a
                                    href="javascript:void(0);"
                                    className="fw-bold text-primary"
                                  >
                                    "NEXEL Event"
                                  </a>
                                </span>
                              </div>
                              <div className="ms-3 d-flex gap-2 align-items-center">
                                <a
                                  href="javascript:void(0);"
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="Make Read"
                                >
                                  <i className="feather feather-check fs-12"></i>
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="View Activity"
                                >
                                  <i className="feather feather-eye fs-12"></i>
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="More Options"
                                >
                                  <i className="feather feather-more-vertical"></i>
                                </a>
                              </div>
                            </li>
                          </ul>
                          <a
                            href="javascript:void(0);"
                            className="d-flex align-items-center text-muted"
                          >
                            <i className="feather feather-more-horizontal fs-12"></i>
                            <span className="fs-10 text-uppercase ms-2 text-truncate-1-line">
                              Load More
                            </span>
                          </a>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="notificationsTab"
                        role="tabpanel"
                      >
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th>Description</th>
                                <th className="wd-250 text-end">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="fw-bold text-dark">
                                    Successful payments
                                  </div>
                                  <small className="fs-12 text-muted">
                                    Receive a notification for every successful
                                    payment.
                                  </small>
                                </td>
                                <td className="text-end">
                                  <div className="form-group select-wd-lg">
                                    <select
                                      className="form-control"
                                      data-select2-selector="icon"
                                    >
                                      <option
                                        value="SMS"
                                        data-icon="feather-smartphone"
                                      >
                                        SMS
                                      </option>
                                      <option
                                        value="Push"
                                        data-icon="feather-bell"
                                      >
                                        Push
                                      </option>
                                      <option
                                        value="Email"
                                        data-icon="feather-mail"
                                        selected
                                      >
                                        Email
                                      </option>
                                      <option
                                        value="Repeat"
                                        data-icon="feather-repeat"
                                      >
                                        Repeat
                                      </option>
                                      <option
                                        value="Deactivate"
                                        data-icon="feather-bell-off"
                                      >
                                        Deactivate
                                      </option>
                                      <option
                                        value="SMS+Push"
                                        data-icon="feather-smartphone"
                                      >
                                        SMS + Push
                                      </option>
                                      <option
                                        value="Email+Push"
                                        data-icon="feather-mail"
                                      >
                                        Email + Push
                                      </option>
                                      <option
                                        value="SMS+Email"
                                        data-icon="feather-smartphone"
                                      >
                                        SMS + Email
                                      </option>
                                      <option
                                        value="SMS+Push+Email"
                                        data-icon="feather-smartphone"
                                      >
                                        SMS + Push + Email
                                      </option>
                                    </select>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="fw-bold text-dark">
                                    Customer payment dispute
                                  </div>
                                  <small className="fs-12 text-muted">
                                    Receive a notification if a payment is
                                    disputed by a customer and for dispute
                                    purposes.{" "}
                                  </small>
                                </td>
                                <td className="text-end">
                                  <div className="form-group select-wd-lg">
                                    <select
                                      className="form-control"
                                      data-select2-selector="icon"
                                    >
                                      <option
                                        value="SMS"
                                        data-icon="feather-smartphone"
                                      >
                                        SMS
                                      </option>
                                      <option
                                        value="Push"
                                        data-icon="feather-bell"
                                      >
                                        Push
                                      </option>
                                      <option
                                        value="Email"
                                        data-icon="feather-mail"
                                      >
                                        Email
                                      </option>
                                      <option
                                        value="Repeat"
                                        data-icon="feather-repeat"
                                      >
                                        Repeat
                                      </option>
                                      <option
                                        value="Deactivate"
                                        data-icon="feather-bell-off"
                                        selected
                                      >
                                        Deactivate
                                      </option>
                                      <option
                                        value="SMS+Push"
                                        data-icon="feather-smartphone"
                                      >
                                        SMS + Push
                                      </option>
                                      <option
                                        value="Email+Push"
                                        data-icon="feather-mail"
                                      >
                                        Email + Push
                                      </option>
                                      <option
                                        value="SMS+Email"
                                        data-icon="feather-smartphone"
                                      >
                                        SMS + Email
                                      </option>
                                      <option
                                        value="SMS+Push+Email"
                                        data-icon="feather-smartphone"
                                      >
                                        SMS + Push + Email
                                      </option>
                                    </select>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- [ Main Content ] end --> */}
          </div>
          {/* <!-- [ Footer ] start --> */}
          {/* <Footer /> */}
        </main>

        <div
          className="modal fade"
          id="passwordModal"
          tabIndex={-1}
          aria-labelledby="passwordModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex flex-column">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Reset Client Password
                  </h5>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p className="text-secondary">
                  The client's new credentials will be sent to their email
                  address
                </p>
                {/* <form className="w-100">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <label htmlFor="oldPassword">Client Email</label>
                      <div className={`d-flex`}>
                        <input
                          type="email"
                          className="form-control border-end-0 rounded-start rounded-0"
                          placeholder="Client Email"
                          required
                          id="email"
                          value={clientEmail ?? ""}
                          name="email"
                          onChange={(e) => setClientEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </form> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleResetPassword}
                  disabled={isloading || !clientData}
                  data-bs-dismiss="modal"
                >
                  {isloading && <span className="loader"></span>}
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* delete modal  */}
        <div
          className="modal fade"
          id="deleteModal"
          tabIndex={-1}
          aria-labelledby="deleteModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Desactivate Account
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {" "}
                Do you really want to desactivate the account of user{" "}
                <span className="fw-bold">
                  {clientData?.firstname} {clientData?.lastname}
                </span>{" "}
                ?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDesactivate}
                  disabled={isloading}
                  data-bs-dismiss="modal"
                >
                  {isloading && <span className="loader"></span>}
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default ClientDetails;
