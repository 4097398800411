import { useState, useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { whatsappService } from "../services/whatsappService";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

type Props = {
  phoneNumber: string | undefined;
};

const selectOptions = [
  { value: 3, label: "3" },
  { value: 5, label: "5" },
  { value: 7, label: "7" },
  { value: 10, label: "10" },
];

const ExtractContacts: React.FC<Props> = ({ phoneNumber }) => {
  const [extractedGroups, setExtractedGroups] = useState<{
    totalGroups: number | undefined;
    groups: any[];
  }>({ totalGroups: undefined, groups: [] });
  const [isExtracting, setIsExtracting] = useState(false);
  const [pageSize, setPageSize] = useState(3);
  const [selectedGroups, setSelectedGroups] = useState<Set<string>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();

  // Format phone number by removing the '+' sign
  const formattedPhoneNumber = phoneNumber?.startsWith("+")
    ? phoneNumber.slice(1)
    : phoneNumber;

  // Function to handle group extraction
  const getExtractedGroups = useCallback(async () => {
    if (!phoneNumber || !formattedPhoneNumber) {
      toast.error("You need to enter a valid phone number.");
      return;
    }
    setIsExtracting(true);
    try {
      const res = await whatsappService.extractWhatsappGroups(
        formattedPhoneNumber,
        {
          page: 1,
          pageSize,
        }
      );
      if (res.status === -1) {
        toast.error(res.message);
        return;
      }
      setExtractedGroups(res.data);
    } catch (error) {
      toast.error(
        "An error occurred while extracting groups. Please try again later."
      );
    } finally {
      setIsExtracting(false);
    }
  }, [phoneNumber, formattedPhoneNumber, pageSize]);

  // Function to handle contact extraction from selected groups
  const handleExtractContacts = async () => {
    if (selectedGroups.size === 0) {
      toast.error("No groups selected for extraction.");
      return;
    }
    setIsExtracting(true);
    try {
      const groupIds = Array.from(selectedGroups);
      const res = await whatsappService.extractContactsFromGroup({
        phoneNumber: formattedPhoneNumber!,
        groupIds,
      });
      if (res.status === -1) {
        toast.error(res.message);
        return;
      }
      toast.success(
        "Contacts extracted successfully. You'll be redirected to your contatcs list",
        { duration: 4000 }
      );
      navigate("/contacts?whatsapp=true");
    } catch (error) {
      toast.error("An error occurred during contact extraction.");
    } finally {
      setIsExtracting(false);
    }
  };

  // Handle checkbox selection
  const handleCheckboxChange = (groupId: string) => {
    setSelectedGroups((prev) => {
      const updated = new Set(prev);
      if (updated.has(groupId)) {
        updated.delete(groupId);
      } else {
        updated.add(groupId);
      }
      return updated;
    });
  };

  // Handle select all checkbox
  const handleSelectAllChange = () => {
    setSelectAll((prev) => {
      const newState = !prev;
      if (newState) {
        const allGroupIds = new Set(
          extractedGroups.groups.map((group) => group.id._serialized)
        );
        setSelectedGroups(allGroupIds);
      } else {
        setSelectedGroups(new Set());
      }
      return newState;
    });
  };

  // Reset select all when groups change
  useEffect(() => {
    setSelectAll(false);
    setSelectedGroups(new Set());
  }, [extractedGroups.groups]);

  return (
    <div className="row">
      <div className="col-12 d-flex justify-content-between align-items-center mb-3">
        {extractedGroups.groups.length !== 0 && (
          <h6>Select the groups you want to extract contacts from</h6>
        )}
        {selectedGroups.size > 0 && (
          <button
            className="btn btn-primary"
            onClick={handleExtractContacts}
            disabled={isExtracting}
          >
            {isExtracting && <span className="loader"></span>}
            Extract Contacts from Selected Groups
          </button>
        )}
      </div>
      <div className="col-xxl-3 col-md-4">
        <div className="card stretch stretch-full">
          <div className="card-body">
            <div className="step-content d-flex flex-column justify-content-center align-items-center gap-3 my-2">
              <div>
                <h6 className="mb-2">Select the number of groups to extract</h6>
                <Select
                  className="basic-single"
                  defaultValue={selectOptions[0]}
                  isLoading={isExtracting}
                  isDisabled={isExtracting}
                  name="groups"
                  options={selectOptions}
                  onChange={(option) =>
                    setPageSize(option?.value ?? selectOptions[0].value)
                  }
                />
                <button
                  className="btn btn-primary mt-3"
                  disabled={isExtracting}
                  onClick={getExtractedGroups}
                >
                  {isExtracting && <span className="loader"></span>}
                  Extract
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-6 col-md-8">
        <div className="card stretch stretch-full">
          <div className="card-body">
            {isExtracting ? (
              <div className="p-3">
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" height={60} />
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="rectangular" height={60} />
                  <Skeleton variant="rectangular" height={60} />
                </Stack>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table table-hover" id="customerList">
                  <thead>
                    <tr>
                      <th className="wd-30">
                        <div className="btn-group mb-1">
                          <div className="custom-control custom-checkbox ms-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="checkAllCustomer"
                              checked={selectAll}
                              onChange={handleSelectAllChange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="checkAllCustomer"
                            ></label>
                          </div>
                        </div>
                      </th>
                      <th className="text-nowrap">Group name</th>
                      <th className="text-nowrap">Number of contacts</th>
                    </tr>
                  </thead>
                  <tbody>
                    {extractedGroups.groups.length !== 0 &&
                      extractedGroups.groups.map((group, index) => (
                        <tr key={index} className="single-item">
                          <td>
                            <div className="item-checkbox ms-1">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input checkbox"
                                  id={group.name}
                                  checked={selectedGroups.has(
                                    group.id._serialized
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(group.id._serialized)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={group.name}
                                ></label>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-truncate-1-line">
                              {group.name}
                            </span>
                          </td>
                          <td>{group.groupMetaData.length}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {extractedGroups.groups.length === 0 &&
                  !extractedGroups.totalGroups && (
                    <h5 className="text-center my-5 w-100">
                      Your extracted groups will appear here
                    </h5>
                  )}
                {extractedGroups.groups.length === 0 &&
                  extractedGroups.totalGroups === 0 && (
                    <h5 className="text-center my-5 w-100">
                      No groups available
                    </h5>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtractContacts;
