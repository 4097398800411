import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPackages,
  subscribePackage,
} from "../../redux/slices/packageSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { Package } from "../../models/Package";
import {
  UilEye,
  UilEyeSlash,
  UilArrowCircleLeft,
  UilUser,
  UilMoneyBillStack,
} from "@iconscout/react-unicons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { accountService } from "../../services/accountService";
import PackageCheckout from "../../components/PackageCheckout";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as string);

type Props = {};

const SubscribePackage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
  const [clientSecret, setClientSecret] = useState<string>();
  const dispatch = useDispatch<AppDispatch>();

  const { packages, loading, error } = useSelector(
    (state: RootState) => state.packages
  );
  const { currentUser } = useSelector((state: RootState) => state.auth);

  const handleCreatePaymentIntent = async (amount: number) => {
    if (!amount || amount === 0) {
      toast.error("Please enter a valid amount.");
      return;
    }
    try {
      setIsLoading(true);

      accountService
        .createPaymentIntent({ amount, currency: "usd" })
        .then((res: any) => {
          const response = res.data;
          setClientSecret(response.clientSecret);
          if (response.clientSecret) {
            setShowSubscribe(true);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectPackage = (pkg: Package) => {
    setSelectedPackage(pkg);
    handleCreatePaymentIntent(pkg.price);
  };

  const options = {
    clientSecret: clientSecret,
    appearance: {
      /*...*/
    },
  };

  const handleSuccess = () => {};

  const handleSubscribe = () => {
    try {
      setIsLoading(true);
      if (!selectedPackage || !currentUser) return;
      const data = {
        packageId: selectedPackage.id,
        userId: currentUser.id,
      };

      dispatch(
        subscribePackage(
          data as {
            userId: number;
            packageId: number;
          }
        )
      )
        .unwrap()
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }
          toast.success(res.message);
          setSelectedPackage(null);
        })
        .catch((error: any) => {
          console.log(error);
          toast.error(`Failed Package Subscription: ${error.message}`);
        });
    } catch (error) {
      toast.error(
        `An error occurred while subscribing package. Please try again later`
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getAllPackages())
      .unwrap()
      .catch((err) => {
        console.error(err);
        toast.error(
          "An error occurred while fetching packages. Please try again later."
        );
      });
  }, [dispatch]);
  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container apps-container apps-email">
          <div className="nxl-content without-header nxl-full-content">
            <div className="main-content d-flex">
              <div
                className="content-area"
                data-scrollbar-target="#psScrollbarInit"
              >
                <div className="content-area-body">
                  {/* <div>
                      <div>
                        <button
                          onClick={() => setShowSubscribe(false)}
                          className="btn text-black border-0 mb-3"
                        >
                          <UilArrowCircleLeft />
                          Back
                        </button>
                        <div className="">
                          <h5 className="">Plan Subscription</h5>
                          <div className="row">
                            <div className="col-md-6 border-end bg-white py-3">
                              <Elements
                                stripe={stripePromise}
                                options={options}
                              >
                                <PackageCheckout
                                  handleSuccess={handleSuccess}
                                />
                              </Elements>
                            </div>
                            <div className="col-md-6 bg py-3">
                              <h6>
                                Subscribe to{" "}
                                {selectedPackage.name.toUpperCase()}
                              </h6>
                              <div className="mt-2">
                                <span className="fs-20 fw-bold text-dark">
                                  €{selectedPackage.price}
                                </span>{" "}
                                / <em className="fs-11 fw-medium">Month</em>
                              </div>
                              <div className="p-4 mb-4 d-block bg-soft-100 d-flex justify-content-between align-items-center">
                                <div>
                                  <p className="mb-1 fw-bold">
                                    {selectedPackage.name} Plan
                                  </p>
                                  <p>Billed Monthly</p>
                                </div>
                                <div>
                                  <span className="fs-20 fw-bold text-dark">
                                    €{selectedPackage.price}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                  <div className="row">
                    <div className="col-xl-10 col-md-12 mx-auto">
                      <div className="card stretch stretch-full">
                        <div className="card-header flex-column">
                          <h5 className="text-center">
                            Whatsapp Plans and Pricing
                          </h5>
                          <p className="mb-0">
                            Select the right option for you and send messages
                            easily.
                          </p>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            {packages?.map((pkg, index) => (
                              <div key={pkg.id} className="col-12 col-md-4 ">
                                <div
                                  className={`p-4 mb-4 d-block ${
                                    index % 2 === 0
                                      ? "bg-soft-100"
                                      : "bg-soft-200"
                                  } border border-dashed border-gray-5 rounded-1 cursor-pointer`}
                                >
                                  <div className="d-flex align-items-center justify-content-between w-100">
                                    <h6 className="fs-13 fw-bold">
                                      {pkg.name.toUpperCase()}
                                    </h6>
                                  </div>
                                  <p className="fs-12 fw-normal text-primary">
                                    Total Messages:{" "}
                                    <strong>{pkg.totalMessages}</strong>
                                  </p>

                                  <p className="fs-12 fw-normal text-muted text-truncate-2-line">
                                    {pkg.description}
                                  </p>
                                  <div className="mt-4">
                                    <span className="fs-20 fw-bold text-dark">
                                      €{pkg.price}
                                    </span>
                                  </div>
                                  <button
                                    className="btn btn-primary w-100 mt-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#subscribeModal"
                                    onClick={() => handleSelectPackage(pkg)}
                                  >
                                    Try it now
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <div
          className="modal fade"
          id="subscribeModal"
          tabIndex={-1}
          data-bs-keyboard="false"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalTitleId">
                  Subscribe to the {selectedPackage?.name.toUpperCase()} plan
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h6 className="fs-14">
                  Do you confirm your subscription to the{" "}
                  <span className="fw-bold">{selectedPackage?.name} plan</span>{" "}
                  ?
                </h6>
                <div>
                  <h6 className="fs-14">
                    Price :{" "}
                    <span className="text-primary">
                      €{selectedPackage?.price}
                    </span>
                  </h6>
                  <p className="fs-14 mb-0">
                    Note that the corresponding amount will be debited from your
                    account balance.
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-warning" data-bs-dismiss="modal">
                  Cancel
                </button>
                <button
                  id="btn-n-add"
                  className="btn btn-success"
                  disabled={isLoading}
                  data-bs-dismiss="modal"
                  onClick={handleSubscribe}
                >
                  {isLoading && <span className="loader"></span>}
                  subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default SubscribePackage;
