import React from "react";

type BillingProps = {};

const Billing:React.FC<BillingProps> = () => {
  return (
    <div className="tab-pane fade" id="billingTab" role="tabpanel">
      <div
        className="alert alert-dismissible m-4 p-4 d-flex alert-soft-teal-message"
        role="alert"
      >
        <div className="me-4 d-none d-md-block">
          <i className="feather feather-alert-octagon fs-1"></i>
        </div>
        <div>
          <p className="fw-bold mb-1 text-truncate-1-line">
            We need your attention!
          </p>
          <p className="fs-12 fw-medium text-truncate-1-line">
            Your payment was declined. To start using tools, please{" "}
            <strong>Add Payment Method</strong>
          </p>
          <a
            href="javascript:void(0);"
            className="btn btn-sm bg-soft-teal text-teal d-inline-block"
          >
            Add Payment Method
          </a>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div className="subscription-plan px-4 pt-4">
        <div className="mb-4 d-flex align-items-center justify-content-between">
          <h5 className="fw-bold mb-0">Subscription & Plan:</h5>
          <a href="javascript:void(0);" className="btn btn-sm btn-light-brand">
            4 days remaining
          </a>
        </div>
        <div className="p-4 mb-4 d-xxl-flex d-xl-block d-md-flex align-items-center justify-content-between gap-4 border border-dashed border-gray-5 rounded-1">
          <div>
            <div className="fs-14 fw-bold text-dark mb-1">
              Your current plan is{" "}
              <span className="badge bg-primary text-white ms-2">
                Team Plan
              </span>
            </div>
            <div className="fs-12 text-muted">A simple start for everyone</div>
          </div>
          <div className="my-3 my-xxl-0 my-md-3 my-md-0">
            <div className="fs-20 text-dark">
              <span className="fw-bold">$29.99</span> /{" "}
              <em className="fs-11 fw-medium">Month</em>
            </div>
            <div className="fs-12 text-muted mt-1">
              Billed Monthly / Next payment on 12/10/2023 for{" "}
              <strong className="text-dark">$62.48</strong>
            </div>
          </div>
          <div className="hstack gap-3">
            <a href="javascript:void(0);" className="text-danger">
              Cancel Plan
            </a>
            <a href="javascript:void(0);" className="btn btn-light-brand">
              Update Plan
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-4 col-xl-12 col-lg-4">
            <a
              href="javascript:void(0);"
              className="p-4 mb-4 d-block bg-soft-100 border border-dashed border-gray-5 rounded-1"
            >
              <h6 className="fs-13 fw-bold">BASIC</h6>
              <p className="fs-12 fw-normal text-muted">
                Starter plan for individuals.
              </p>
              <p className="fs-12 fw-normal text-muted text-truncate-2-line">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod
                ipsa id corrupti modi, impedit exercitationem harum voluptates
                reiciendis.
              </p>
              <div className="mt-4">
                <span className="fs-16 fw-bold text-dark">$12.99</span> /{" "}
                <em className="fs-11 fw-medium">Month</em>
              </div>
            </a>
          </div>
          <div className="col-xxl-4 col-xl-12 col-lg-4">
            <a
              href="javascript:void(0);"
              className="p-4 mb-4 d-block bg-soft-200 border border-dashed border-gray-5 rounded-1 position-relative"
            >
              <h6 className="fs-13 fw-bold">TEAM</h6>
              <p className="fs-12 fw-normal text-muted">
                Collaborate up to 10 people.
              </p>
              <p className="fs-12 fw-normal text-muted text-truncate-2-line">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod
                ipsa id corrupti modi, impedit exercitationem harum voluptates
                reiciendis.
              </p>
              <div className="mt-4">
                <span className="fs-16 fw-bold text-dark">$29.99</span> /{" "}
                <em className="fs-11 fw-medium">Month</em>
              </div>
              <div className="position-absolute top-0 start-50 translate-middle">
                <i className="feather-check fs-12 bg-primary text-white p-1 rounded-circle"></i>
              </div>
            </a>
          </div>
          <div className="col-xxl-4 col-xl-12 col-lg-4">
            <a
              href="javascript:void(0);"
              className="p-4 mb-4 d-block bg-soft-100 border border-dashed border-gray-5 rounded-1"
            >
              <h6 className="fs-13 fw-bold">ENTERPRISE</h6>
              <p className="fs-12 fw-normal text-muted">
                For bigger businesses.
              </p>
              <p className="fs-12 fw-normal text-muted text-truncate-2-line">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod
                ipsa id corrupti modi, impedit exercitationem harum voluptates
                reiciendis.
              </p>
              <div className="mt-4">
                <span className="fs-16 fw-bold text-dark">$49.99</span> /{" "}
                <em className="fs-11 fw-medium">Month</em>
              </div>
            </a>
          </div>
        </div>
      </div>
      {/* <hr className="mt-2" />
      <div className="payment-methord px-4">
        <div className="mb-4 d-flex align-items-center justify-content-between">
          <h5 className="fw-bold mb-0">Payment Methords:</h5>
          <a href="javascript:void(0);" className="btn btn-sm btn-light-brand">
            Add Methord
          </a>
        </div>
        <div className="row">
          <div className="col-xxl-6 col-xl-12 col-lg-6">
            <div className="px-4 py-2 mb-4 d-sm-flex justify-content-between border border-dashed border-gray-3 rounded-1 position-relative">
              <div className="d-sm-flex align-items-center">
                <div className="wd-100">
                  <img
                    src="https://getnexel.netlify.app/assets/images/payment/mastercard.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="ms-0 ms-sm-3">
                  <div className="text-dark fw-bold mb-2">Alexandra Della</div>
                  <div className="mb-0 text-truncate-1-line">
                    5155 **** **** ****
                  </div>
                  <small className="fs-10 fw-medium text-uppercase text-muted text-truncate-1-line">
                    Card expires at 12/24
                  </small>
                </div>
              </div>
              <div className="hstack gap-3 mt-3 mt-sm-0">
                <a href="javascript:void(0);" className="text-danger">
                  Delete
                </a>
                <a href="javascript:void(0);" className="btn btn-sm btn-light">
                  Edit
                </a>
              </div>
              <div className="position-absolute top-50 start-0 translate-middle">
                <i className="feather-check fs-12 bg-primary text-white p-1 rounded-circle"></i>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-12 col-lg-6">
            <div className="px-4 py-2 mb-4 d-sm-flex justify-content-between border border-dashed border-gray-3 rounded-1">
              <div className="d-sm-flex align-items-center">
                <div className="wd-100">
                  <img
                    src="https://getnexel.netlify.app/assets/images/payment/visa.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="ms-0 ms-sm-3">
                  <div className="text-dark fw-bold mb-2">Alexandra Della</div>
                  <div className="mb-0 text-truncate-1-line">
                    4236 **** **** ****
                  </div>
                  <small className="fs-10 fw-medium text-uppercase text-muted text-truncate-1-line">
                    Card expires at 11/23
                  </small>
                </div>
              </div>
              <div className="hstack gap-3 mt-3 mt-sm-0">
                <a href="javascript:void(0);" className="text-danger">
                  Delete
                </a>
                <a href="javascript:void(0);" className="btn btn-sm btn-light">
                  Edit
                </a>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-12 col-lg-6">
            <div className="px-4 py-2 mb-4 d-sm-flex justify-content-between border border-dashed border-gray-3 rounded-1">
              <div className="d-sm-flex align-items-center">
                <div className="wd-100">
                  <img
                    src="https://getnexel.netlify.app/assets/images/payment/american-express.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="ms-0 ms-sm-3">
                  <div className="text-dark fw-bold mb-2">Alexandra Della</div>
                  <div className="mb-0 text-truncate-1-line">
                    3437 **** **** ****
                  </div>
                  <small className="fs-10 fw-medium text-uppercase text-muted text-truncate-1-line">
                    Card expires at 11/24
                  </small>
                </div>
              </div>
              <div className="hstack gap-3 mt-3 mt-sm-0">
                <a href="javascript:void(0);" className="text-danger">
                  Delete
                </a>
                <a href="javascript:void(0);" className="btn btn-sm btn-light">
                  Edit
                </a>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-12 col-lg-6">
            <div className="px-4 py-2 mb-4 d-sm-flex justify-content-between border border-dashed border-gray-3 rounded-1">
              <div className="d-sm-flex align-items-center">
                <div className="wd-100">
                  <img
                    src="https://getnexel.netlify.app/assets/images/payment/discover.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="ms-0 ms-sm-3">
                  <div className="text-dark fw-bold mb-2">Alexandra Della</div>
                  <div className="mb-0 text-truncate-1-line">
                    6011 **** **** ****
                  </div>
                  <small className="fs-10 fw-medium text-uppercase text-muted text-truncate-1-line">
                    Card expires at 11/25
                  </small>
                </div>
              </div>
              <div className="hstack gap-3 mt-3 mt-sm-0">
                <a href="javascript:void(0);" className="text-danger">
                  Delete
                </a>
                <a href="javascript:void(0);" className="btn btn-sm btn-light">
                  Edit
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-2" />
      <div className="payment-history">
        <div className="mb-4 px-4 d-flex align-items-center justify-content-between">
          <h5 className="fw-bold mb-0">Billing History:</h5>
          <a href="javascript:void(0);" className="btn btn-sm btn-light-brand">
            Alls History
          </a>
        </div>
        <div className="table-responsive">
          <table className="table mb-0">
            <thead>
              <tr className="border-top">
                <th>ID</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a href="javascript:void(0);">#258963</a>
                </td>
                <td>02 NOV, 2022</td>
                <td>$350</td>
                <td>
                  <span className="badge bg-soft-success text-success">
                    Completed
                  </span>
                </td>
                <td className="hstack justify-content-end gap-4 text-end">
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Sent Mail"
                  >
                    <i className="feather feather-send fs-12"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Invoice Details"
                  >
                    <i className="feather feather-eye fs-12"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="More Options"
                  >
                    <i className="feather feather-more-vertical fs-12"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="javascript:void(0);">#987456</a>
                </td>
                <td>05 DEC, 2022</td>
                <td>$590</td>
                <td>
                  <span className="badge bg-soft-warning text-warning">
                    Pendign
                  </span>
                </td>
                <td className="hstack justify-content-end gap-4 text-end">
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Sent Mail"
                  >
                    <i className="feather feather-send fs-12"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Invoice Details"
                  >
                    <i className="feather feather-eye fs-12"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="More Options"
                  >
                    <i className="feather feather-more-vertical fs-12"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="javascript:void(0);">#456321</a>
                </td>
                <td>31 NOV, 2022</td>
                <td>$450</td>
                <td>
                  <span className="badge bg-soft-danger text-danger">
                    Reject
                  </span>
                </td>
                <td className="hstack justify-content-end gap-4 text-end">
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Sent Mail"
                  >
                    <i className="feather feather-send fs-12"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Invoice Details"
                  >
                    <i className="feather feather-eye fs-12"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="More Options"
                  >
                    <i className="feather feather-more-vertical fs-12"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="javascript:void(0);">#357951</a>
                </td>
                <td>03 JAN, 2023</td>
                <td>$250</td>
                <td>
                  <span className="badge bg-soft-success text-success">
                    Completed
                  </span>
                </td>
                <td className="hstack justify-content-end gap-4 text-end">
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Sent Mail"
                  >
                    <i className="feather feather-send fs-12"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Invoice Details"
                  >
                    <i className="feather feather-eye fs-12"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="More Options"
                  >
                    <i className="feather feather-more-vertical fs-12"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="javascript:void(0);">#258963</a>
                </td>
                <td>02 NOV, 2022</td>
                <td>$350</td>
                <td>
                  <span className="badge bg-soft-success text-success">
                    Completed
                  </span>
                </td>
                <td className="hstack justify-content-end gap-4 text-end">
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Sent Mail"
                  >
                    <i className="feather feather-send fs-12"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Invoice Details"
                  >
                    <i className="feather feather-eye fs-12"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="More Options"
                  >
                    <i className="feather feather-more-vertical fs-12"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="javascript:void(0);">#357951</a>
                </td>
                <td>03 JAN, 2023</td>
                <td>$250</td>
                <td>
                  <span className="badge bg-soft-success text-success">
                    Completed
                  </span>
                </td>
                <td className="hstack justify-content-end gap-4 text-end">
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Sent Mail"
                  >
                    <i className="feather feather-send fs-12"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="Invoice Details"
                  >
                    <i className="feather feather-eye fs-12"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title="More Options"
                  >
                    <i className="feather feather-more-vertical fs-12"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
    </div>
  );
};

export default Billing;
