import React, { useState } from "react";
import DashboardCard from "../../components/DashboardCard";
import {
  UilDollarSignAlt,
  UilCommentAltMessage,
  UilUsersAlt,
  UilReceiptAlt,
  UilAlignLeft,
  UilArrowRight,
  UilPlus,
  UilMessage,
  UilAngleRightB,
  UilSearch,
  UilMoon,
  UilSun,
  UilCheck,
  UilBell,
} from "@iconscout/react-unicons";
import PaymentRecords from "../../components/PaymentRecords";
import Tickets from "../../components/Tickets";
import Footer from "../../components/Footer";
import StepperComponent from "../../components/StepperComponent";

type Props = {};

const TestPage = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const steps = [
    { label: "Step 1", icon: <UilSearch /> },
    { label: "Step 2", icon: <UilMoon /> },
    { label: "Step 3", icon: <UilSun /> },
  ];
  return (
    <div>
      <main className="nxl-container">
        <div className="nxl-content">
          {/* <!-- [ Main Content ] start --> */}
          <div className="main-content">
            <div className="row">
              {/* <StepperComponent
                steps={steps}
                currentStep={currentStep}
                onNext={handleNext}
                onBack={handleBack}
              /> */}
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default TestPage;
