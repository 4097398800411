import React, { useEffect, useState } from "react";
import {
  UilEye,
  UilEyeSlash,
  UilArrowCircleLeft,
  UilUser,
  UilMoneyBillStack,
} from "@iconscout/react-unicons";
import CheckoutForm from "./CheckoutForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { fetchBalance } from "../redux/slices/accountSlice";
import { loadStripe } from "@stripe/stripe-js";
import { accountService } from "../services/accountService";
import { Skeleton } from "@mui/material";
import StepperComponent from "./StepperComponent";
import toast from "react-hot-toast";
import { Elements } from "@stripe/react-stripe-js";
import moment from "moment";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as string);

const steps = [
  { label: "Topup AMount", icon: <UilMoneyBillStack /> },
  { label: "Account Details", icon: <UilUser /> },
];

type Recharge = {
  amount: number;
  date: string;
  id: number;
  payment_status: string;
  recharge_code: string;
  stripe_payment_intent_id: string;
};

const AccountBalance: React.FC = () => {
  const [showTopUp, setShowTopUp] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const [recharges, setRecharges] = useState<Recharge[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const { balance, isLoading, errors } = useSelector(
    (state: RootState) => state.account
  );

  const [amount, setAmount] = useState<number | null>(null);
  const [clientSecret, setClientSecret] = useState<string>();
  const [currentStep, setCurrentStep] = useState(0);

  const options = {
    clientSecret: clientSecret,
    appearance: {
      /*...*/
    },
  };

  const getRecharges = async () => {
    try {
      setIsLoading(true);
      await accountService
        .getRecharges()
        .then((res: any) => {
          if (res.status === -1) {
            return;
          }
          console.log(res.data);
          setRecharges(res.data);
        })
        .catch((err: any) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      // toast.error(
      //   "An error occured while fetching clients. Please try again later."
      // );
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(parseFloat(e.target.value));
  };

  const handleCreatePaymentIntent = async () => {
    if (!amount || amount === 0) {
      toast.error("Please enter a valid amount.");
      return;
    }
    try {
      setIsLoading(true);

      accountService
        .createPaymentIntent({ amount, currency: "usd" })
        .then((res: any) => {
          const response = res.data;
          setClientSecret(response.clientSecret);
          if (response.clientSecret) {
            setCurrentStep(currentStep + 1);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchBalance());
  }, [dispatch]);
  useEffect(() => {
    getRecharges();
  }, []);

  const toggleShowBalance = () => {
    setShowBalance(!showBalance);
  };

  const handleSuccess = () => {
    setCurrentStep(0);
    setAmount(null);
  };

  return (
    <div style={{ overflowY: "auto" }}>
      {/* <LoaderOverlay isLoading={isLoading} /> */}
      <div className="content-area" data-scrollbar-target="#psScrollbarInit">
        <div className="content-area-body">
          {showTopUp ? (
            <div>
              <button
                onClick={() => setShowTopUp(false)}
                className="btn text-black border-0 mb-3"
              >
                <UilArrowCircleLeft />
                Back
              </button>
              <div className="card">
                <div className="card-header">Topup Balance</div>
                <div className="card-body">
                  <StepperComponent
                    steps={steps}
                    currentStep={currentStep}
                    onNext={handleCreatePaymentIntent}
                    onBack={handleBack}
                    isLoading={isLoading}
                  >
                    {currentStep === 0 && (
                      <form>
                        <div className="mb-3 col-6 mx-auto my-3">
                          <label htmlFor="amount">Amount</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Amount"
                            id="amount"
                            value={amount || ""}
                            onChange={handleAmountChange}
                            min="0"
                          />
                        </div>
                      </form>
                    )}
                    {currentStep === 1 && (
                      <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm handleSuccess={handleSuccess} />
                      </Elements>
                    )}
                  </StepperComponent>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-xxl-4 col-xl-6 col-md-12">
                <div className="card stretch stretch-full">
                  <div className="card-header">Account Balance</div>
                  <div className="card-body">
                    <div className="mb-4 text-center">
                      <div className="w-100 p-4 mb-4 d-block d-md-flex align-items-center justify-content-between gap-4 border border-dashed border-gray-5 rounded-1">
                        <div className="text-center w-100">
                          <div className="fs-14 fw-bold text-dark mb-1">
                            Current Balance
                          </div>
                          <h5 className="text-primary d-flex align-items-center gap-3 justify-content-center">
                            {isLoading ? (
                              <Skeleton
                                variant="text"
                                style={{ width: "100px" }}
                                sx={{ fontSize: "1rem" }}
                              />
                            ) : (
                              <span>
                                {showBalance
                                  ? `$${Number(balance)?.toFixed(2) || "0.00"}`
                                  : "******"}
                              </span>
                            )}
                            <span
                              className="cursor-pointer"
                              onClick={toggleShowBalance}
                            >
                              {showBalance ? <UilEyeSlash /> : <UilEye />}
                            </span>
                          </h5>
                          <div className="fs-12 text-muted">
                            A simple start for everyone
                          </div>
                        </div>
                      </div>
                      <button
                        className="mx-auto btn btn-primary"
                        onClick={() => setShowTopUp(true)}
                      >
                        Top Up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-8 col-xl-6 col-md-12">
                <div className="card stretch stretch-full">
                  <div className="card-header">Recent Payments</div>
                  <div className="card-body">
                    <div className="recent-activity p-4 pb-0">
                      <div className="mb-4 pb-2 d-flex justify-content-between">
                        <h5 className="fw-bold">Recent Operations:</h5>
                        <button className="btn btn-sm btn-light-brand">
                          View All
                        </button>
                      </div>
                      {/* <ul className="list-unstyled activity-feed">
                        {recharges.map((item, index) => {
                          const styleClass =
                            index % 2 === 0
                              ? "feed-item-success"
                              : "feed-item-info";

                          return (
                            <li
                              key={item.id}
                              className={`d-flex justify-content-between feed-item ${styleClass}`}
                            >
                              <div>
                                <span className="text-truncate-1-line lead_date">
                                  Recharge of{" "}
                                  <span className="fw-bold text-black">
                                    ${item.amount}
                                  </span>{" "}
                                  <span className="date">
                                    [
                                    {moment(item.date).format(
                                      "DD/MM/YYYY HH:mm"
                                    )}
                                    ]
                                  </span>
                                </span>
                                <span className="text">
                                  Status:{" "}
                                  <span className="fw-bold text-primary">
                                    {item.payment_status
                                      .charAt(0)
                                      .toUpperCase() +
                                      item.payment_status.slice(1)}
                                  </span>
                                </span>
                              </div>
                            </li>
                          );
                        })}
                      </ul> */}
                      <ul className="list-unstyled activity-feed">
                        <li
                          className={`d-flex justify-content-between feed-item feed-item-success`}
                        >
                          <div>
                            <span className="text-truncate-1-line lead_date">
                              Recharge of{" "}
                              <span className="fw-bold text-black">$700</span>{" "}
                              <span className="date">
                                [
                                {moment("12-08-2024").format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                                ]
                              </span>
                            </span>
                            <span className="text">
                              Status:{" "}
                              <span className="fw-bold text-primary">
                                COMPLETED
                              </span>
                            </span>
                          </div>
                          {/* <div className="ms-3 d-flex gap-2 align-items-center">
                                <div
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="Make Read"
                                >
                                  <i className="feather feather-check fs-12"></i>
                                </div>
                                <div
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="View Activity"
                                >
                                  <i className="feather feather-eye fs-12"></i>
                                </div>
                                <div
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="More Options"
                                >
                                  <i className="feather feather-more-vertical"></i>
                                </div>
                              </div> */}
                        </li>
                        <li
                          className={`d-flex justify-content-between feed-item feed-item-info`}
                        >
                          <div>
                            <span className="text-truncate-1-line lead_date">
                              Recharge of{" "}
                              <span className="fw-bold text-black">$110</span>{" "}
                              <span className="date">
                                [
                                {moment("02-08-2024").format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                                ]
                              </span>
                            </span>
                            <span className="text">
                              Status:{" "}
                              <span className="fw-bold text-primary">
                                COMPLETED
                              </span>
                            </span>
                          </div>
                          {/* <div className="ms-3 d-flex gap-2 align-items-center">
                                <div
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="Make Read"
                                >
                                  <i className="feather feather-check fs-12"></i>
                                </div>
                                <div
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="View Activity"
                                >
                                  <i className="feather feather-eye fs-12"></i>
                                </div>
                                <div
                                  className="avatar-text avatar-sm"
                                  data-bs-toggle="tooltip"
                                  data-bs-trigger="hover"
                                  title="More Options"
                                >
                                  <i className="feather feather-more-vertical"></i>
                                </div>
                              </div> */}
                        </li>
                      </ul>
                      {/* <div className="d-flex align-items-center text-muted">
                        <i className="feather feather-more-horizontal fs-12"></i>
                        <span className="fs-10 text-uppercase ms-2 text-truncate-1-line">
                          Load More
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountBalance;
