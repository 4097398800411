import React, { useState } from "react";
import { useFormik } from "formik";
import { UilPlus } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { pricingService } from "../../services/pricingService";
import {
  countryInitialValues,
  countryValidationSchema,
  operatorInitialValues,
  operatorValidationSchema,
} from "../../validation/FormikValues";
import { Country } from "../../models/Coverage";

type Props = {};

const Coverage = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const handleAddCountry = async (values: any) => {
    try {
      setLoading(true);
      const country: Partial<Country> = {
        name: values.name.toUpperCase(),
        phone_code: values.phone_code,
        iso2: values.iso2.toUpperCase(),
        iso3: values.iso3.toUpperCase(),
        currency: values.currency.toUpperCase(),
      };
      const res = await pricingService.createCountry(country);
      if (res.status === -1) {
        toast.error(res.message);
        return;
      }

      toast.success("Country created succesfully.");
      countryFormik.resetForm();
    } catch (err: any) {
      const error = err.response.data.message;
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const countryFormik = useFormik({
    initialValues: countryInitialValues,
    validationSchema: countryValidationSchema,
    onSubmit: (values) => {
      handleAddCountry(values);
    },
  });
  const operatorFormik = useFormik({
    initialValues: operatorInitialValues,
    validationSchema: operatorValidationSchema,
    onSubmit: () => {},
  });
  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">Add Country</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <span>Back</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
                    <Link className="btn btn-primary" to={"/add-pricing"}>
                      <UilPlus />
                      <span>Add Pricing</span>
                    </Link>
                  </div>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <span className="page-header-right-open-toggle">
                    <i className="feather-align-right fs-20"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="card stretch stretch-full">
                    <div className="card-header">Add New Country</div>
                    <div className="card-body">
                      <form action="" onSubmit={countryFormik.handleSubmit}>
                        <div className="row">
                          <div className="mb-3 col-md-6">
                            <label htmlFor="countryName">Country Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              id="name"
                              {...countryFormik.getFieldProps("name")}
                            />
                            {countryFormik.touched.name &&
                            countryFormik.errors.name ? (
                              <div className="text-danger">
                                {countryFormik.errors.name}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-6">
                            <label htmlFor="phone_code">
                              Phone Number Code
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Phone Code"
                              id="phone_code"
                              {...countryFormik.getFieldProps("phone_code")}
                            />
                            {countryFormik.touched.phone_code &&
                            countryFormik.errors.phone_code ? (
                              <div className="text-danger">
                                {countryFormik.errors.phone_code}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-6">
                            <label htmlFor="iso2">ISO2 Code</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="ISO2 Code"
                              id="iso2"
                              {...countryFormik.getFieldProps("iso2")}
                            />
                            {countryFormik.touched.iso2 &&
                            countryFormik.errors.iso2 ? (
                              <div className="text-danger">
                                {countryFormik.errors.iso2}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-6">
                            <label htmlFor="iso2">ISO3 Code</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="ISO3 Code"
                              id="iso2"
                              {...countryFormik.getFieldProps("iso3")}
                            />
                            {countryFormik.touched.iso3 &&
                            countryFormik.errors.iso3 ? (
                              <div className="text-danger">
                                {countryFormik.errors.iso3}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-12">
                            <label htmlFor="currency">Currency</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Currency"
                              id="currency"
                              {...countryFormik.getFieldProps("currency")}
                            />
                            {countryFormik.touched.currency &&
                            countryFormik.errors.currency ? (
                              <div className="text-danger">
                                {countryFormik.errors.currency}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <button
                          className="btn btn-primary col-md-4 ms-auto"
                          // type="submit"
                          disabled={loading}
                        >
                          {loading && <span className="loader"></span>}
                          Add new
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-6">
                  <div className="card stretch stretch-full">
                    <div className="card-header">Add New Operator</div>
                    <div className="card-body">
                      <form
                        action=""
                        onSubmit={operatorFormik.handleSubmit}
                        className="h-100"
                      >
                        <div className="d-flex flex-column justify-content-between h-100">
                          <div className="row">
                            <div className="mb-3 col-12">
                              <label htmlFor="operatorName">
                                Operator Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Operator Name"
                                id="operatorName"
                                {...operatorFormik.getFieldProps(
                                  "operatorName"
                                )}
                              />
                              {operatorFormik.touched.operatorName &&
                              operatorFormik.errors.operatorName ? (
                                <div className="text-danger">
                                  {operatorFormik.errors.operatorName}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <button
                            className="btn btn-primary mt-auto col-md-4"
                            type="submit"
                            disabled={loading}
                          >
                            {loading && <span className="loader"></span>}
                            Add new
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default Coverage;
