import React, { ReactNode } from "react";
import "../styles/StepperComponent.css";
import {
  UilCheck,
  UilAngleLeftB,
  UilAngleRightB,
} from "@iconscout/react-unicons";

interface Step {
  label: string;
  icon: React.ReactElement;
}

interface StepperComponentProps {
  steps: Step[];
  currentStep: number;
  onNext: () => void;
  onBack: () => void;
  children: ReactNode;
  isLoading: boolean;
}

const StepperComponent: React.FC<StepperComponentProps> = ({
  steps,
  currentStep,
  onNext,
  onBack,
  isLoading,
  children,
}) => {
  return (
    <div>
      <div className="stepper">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div
              className={`step-item ${currentStep === index ? "active" : ""} ${
                currentStep > index ? "completed" : ""
              }`}
            >
              <div className="step-icon">
                {currentStep > index ? (
                  <UilCheck className="check-icon" />
                ) : (
                  step.icon
                )}
              </div>
              <small className="step-label">{step.label}</small>
              {index < steps.length - 1 && <div className="step-line"></div>}
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="stepper-content">
        {/* Render content based on currentStep */}
        {children}
      </div>
      <div className="stepper-controls d-flex gap-2 justify-content-center">
        <button
          className="stepper-button btn border-0 text-primary"
          onClick={onBack}
          disabled={currentStep === 0}
        >
          <UilAngleLeftB />
          Back
        </button>
        <button
          className="stepper-button btn border-0 text-primary"
          onClick={onNext}
          disabled={isLoading || currentStep === steps.length - 1}
        >
          {isLoading && <span className="loader text-primary"></span>}
          {isLoading ? "Loading..." : "Next"}
          <UilAngleRightB />
        </button>
      </div>
    </div>
  );
};

export default StepperComponent;
