import React, { useRef, useState, useEffect } from "react";
import Navbar from "../../components/public/Navbar";
import {
  UilFileEditAlt,
  UilWallet,
  UilMessage,
  UilEuro,
  UilChatBubbleUser,
  UilComments,
  UilMapMarker,
} from "@iconscout/react-unicons";
import { pricingService } from "../../services/pricingService";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackages } from "../../redux/slices/packageSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { Link } from "react-router-dom";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { logoData } from "../../data/LogoData";
import Footer from "../../components/public/Footer";

type HomePageProps = {};

const steps = [
  {
    icon: <UilFileEditAlt size="50" />,
    title: "Create Your Account",
    description:
      "Sign up and set up your account to get started with our messaging platform.",
  },
  {
    icon: <UilWallet size="50" />,
    title: "Add Your Balance",
    description:
      "Deposit funds into your account to enable sending messages. Manage your balance easily through our user-friendly interface.",
  },
  {
    icon: <UilMessage size="50" />,
    title: "Start Sending Messages",
    description:
      "Send SMS and WhatsApp messages seamlessly. For SMS, each message is billed based on the recipient's phone operator. For WhatsApp, select a suitable pricing plan based on your needs.",
  },
];

const HomePage: React.FC<HomePageProps> = () => {
  const [isLoading, setLoading] = useState(false);
  const [pricings, setPricings] = useState<any[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  const { packages } = useSelector((state: RootState) => state.packages);

  const productsRef = useRef<HTMLDivElement>(null);
  const pricingRef = useRef<HTMLDivElement>(null);
  const apiRef = useRef<HTMLDivElement>(null);

  const fetchPricings = async () => {
    try {
      setLoading(true);
      const res = await pricingService.getPricings();
      if (res.status === -1) {
        toast.error("Failed to fetch pricings");
        return;
      }
      setPricings(res.data);
    } catch (error) {
      toast.error(
        "An error occurred while fetching pricings. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPricings();
  }, []);

  useEffect(() => {
    dispatch(getAllPackages())
      .unwrap()
      .catch(() => {
        // Handle error if necessary
      });
  }, [dispatch]);

  const scrollToSection = (sectionId: string) => {
    if (sectionId === "products" && productsRef.current) {
      productsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (sectionId === "pricing" && pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (sectionId === "api" && apiRef.current) {
      apiRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="home">
      <div className="head-bg position-relative">
        <div className="container">
          <Navbar onNavigate={scrollToSection} />
        </div>
        <div className="container center">
          {/* presentation section  */}
          <section className="section-1">
            <div className="row align-items-center my-5">
              <div className="col-md-6 col-lg-5">
                <h1>Seamless Messaging Solutions for Your Business</h1>
                <p className="text-black fw-normal fs-16">
                  Connect with your customers effortlessly using our versatile
                  messaging platform. Customize and scale your messaging
                  strategies for a global audience with advanced features and
                  reliable, instant communication.
                </p>
                <button
                  className="btn btn-light mt-4 bg-white"
                  onClick={() => window.open("/auth", "_blank")}
                >
                  Start sending
                </button>
              </div>
              <div className="col-md-6 col-lg-7 d-none d-md-block">
                <img
                  src="../assets/images/captures/dash1.png"
                  alt="dashboard"
                  className="w-100"
                />
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* products section */}
      <section className="container section-2" ref={productsRef}>
        <div className="row align-items-center my-5 justify-content-between">
          <div className="col-md-5 col-lg-6 mb-3 mb-md-0">
            <img
              src="../assets/images/captures/message.png"
              alt="Send an SMS"
              className="w-100"
            />
          </div>
          <div className="col-md-7 col-lg-5">
            <h1>Effortless Messaging with SMS and WhatsApp</h1>
            <h6 className="text-muted fw-normal">
              Unlock the full potential of your messaging with our versatile
              platform.
            </h6>
            <ul className="list-unstyled mt-3 activity-feed ms-4">
              <li className="feed-item feed-item-success">
                Send messages from files or selected contacts
              </li>
              <li className="feed-item feed-item-info">
                Schedule messages for future delivery
              </li>
              <li className="feed-item feed-item-primary">
                Extract contacts from WhatsApp groups
              </li>
              <li className="feed-item feed-item-danger">
                Customize and manage your campaigns with ease
              </li>
            </ul>
            <button
              className="btn btn-primary mt-4"
              onClick={() => window.open("/auth", "_blank")}
            >
              Start sending
            </button>
          </div>
        </div>
      </section>

      <div className="middle-bg section-2 position-relative">
        {/* how it works */}
        <section className="container center">
          <div className="row justify-content-center">
            <h1 className="text-center">How it Works</h1>
            {steps.map((step, index) => (
              <div key={index} className="col-12 col-md-6 col-lg-4 mt-3">
                <div
                  className={`p-4 mb-4 d-block ${
                    index % 2 === 0 ? "bg-soft-100" : "bg-soft-200"
                  } border border-dashed border-gray-5 rounded-1 cursor-pointer`}
                  style={{ minHeight: "270px" }}
                >
                  <div className="text-primary">{step.icon}</div>
                  <h6 className="my-3">{step.title}</h6>
                  <p className="mb-0">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>

      <div className="third-bg section-2 position-relative">
        {/* pricing section */}
        <section className="container center" ref={pricingRef}>
          <div className="row justify-content-center">
            <h1 className="text-center">All Pricings</h1>
            <div className="col-12 col-lg-10 mt-3">
              <div className="card border-top-0">
                <div className="card-header justify-content-center">
                  <h5 className="text-center">Whatsapp Packages</h5>
                  {/* <ul
                    className="nav nav-tabs flex-wrap w-100 text-center customers-nav-tabs"
                    id="myTab"
                    role="tablist"
                  >
                    <li
                      className="nav-item flex-fill border-top"
                      role="presentation"
                    >
                      <div
                        className="nav-link active cursor-pointer"
                        data-bs-toggle="tab"
                        data-bs-target="#SMSTab"
                        role="tab"
                      >
                        SMS Pricing
                      </div>
                    </li>
                    <li
                      className="nav-item flex-fill border-top"
                      role="presentation"
                    >
                      <div
                        className="nav-link cursor-pointer"
                        data-bs-toggle="tab"
                        data-bs-target="#whatsappTab"
                        role="tab"
                      >
                        Whatsapp Pricing
                      </div>
                    </li>
                  </ul> */}
                </div>
                <div className="card-body">
                  <div className="row p-3">
                    {packages?.map((pkg, index) => (
                      <div key={pkg.id} className="col-12 col-md-4 ">
                        <div
                          className={`p-4 mb-4 d-block ${
                            index % 2 === 0 ? "bg-soft-100" : "bg-soft-200"
                          } border border-dashed border-gray-5 rounded-1 cursor-pointer`}
                        >
                          <div className="d-flex align-items-center justify-content-between w-100">
                            <h6 className="fs-13 fw-bold">
                              {pkg.name.toUpperCase()}
                            </h6>
                          </div>
                          <p className="fs-12 fw-normal text-primary">
                            Total Messages: <strong>{pkg.totalMessages}</strong>
                          </p>

                          <p className="fs-12 fw-normal text-muted text-truncate-2-line">
                            {pkg.description}
                          </p>
                          <div className="mt-4">
                            <span className="fs-20 fw-bold text-dark">
                              €{pkg.price}
                            </span>
                          </div>
                          <button
                            className="btn btn-primary w-100 mt-3"
                            onClick={() => window.open("/auth", "_blank")}
                          >
                            Try it now
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="SMSTab"
                    role="tabpanel"
                  >
                    <div className="table-responsive">
                      <table className="table table-hover" id="customerList">
                        <thead>
                          <tr>
                            <th className="text-nowrap">Country</th>
                            <th className="text-nowrap">Code</th>
                            <th className="text-nowrap">Operator</th>
                            <th className="text-nowrap">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pricings.length > 0 ? (
                            pricings.map((pricing, index) => (
                              <tr key={index} className="single-item">
                                <td>
                                  <span className="text-truncate-1-line">
                                    {pricing?.country?.name ?? "N/A"}
                                  </span>
                                </td>
                                <td>
                                  <div className="hstack gap-3 text-black">
                                    <span className="text-truncate-1-line">
                                      {pricing?.country?.iso2 ?? "N/A"}(
                                      {pricing?.country?.phone_code ?? "N/A"})
                                    </span>
                                  </div>
                                </td>
                                <td>{pricing?.name ?? "N/A"}</td>
                                <td className="d-flex align-items-center">
                                  {pricing?.pricing?.price_receller ?? "N/A"}{" "}
                                  <UilEuro className="ht-10" />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4} className="text-center my-5">
                                Your pricing list will appear here
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="whatsappTab"
                    role="tabpanel"
                  >
                    <div className="row p-3">
                      {packages?.map((pkg, index) => (
                        <div key={pkg.id} className="col-12 col-md-4 ">
                          <div
                            className={`p-4 mb-4 d-block ${
                              index % 2 === 0 ? "bg-soft-100" : "bg-soft-200"
                            } border border-dashed border-gray-5 rounded-1 cursor-pointer`}
                          >
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <h6 className="fs-13 fw-bold">
                                {pkg.name.toUpperCase()}
                              </h6>
                            </div>
                            <p className="fs-12 fw-normal text-primary">
                              Total Messages:{" "}
                              <strong>{pkg.totalMessages}</strong>
                            </p>

                            <p className="fs-12 fw-normal text-muted text-truncate-2-line">
                              {pkg.description}
                            </p>
                            <div className="mt-4">
                              <span className="fs-20 fw-bold text-dark">
                                €{pkg.price}
                              </span>
                            </div>
                            <button className="btn btn-primary w-100 mt-3">
                              Try it now
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="section-1 position-relative" ref={apiRef}>
        <div className="container ">
          <h1 className="text-center mb-3">Coming Soon</h1>
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7 col-lg-5">
              <h3>Supercharge Your App with Our API</h3>
              <h6 className="text-muted fw-normal">
                Elevate your application’s communication capabilities with
                effortless integration.
              </h6>
              <ul className="list-unstyled mt-3 activity-feed ms-4">
                <li className="feed-item feed-item-success">
                  Seamlessly embed messaging functions into your platform
                </li>
                <li className="feed-item feed-item-info">
                  Automate and schedule messaging tasks for efficient
                  communication
                </li>
                <li className="feed-item feed-item-primary">
                  Integrate WhatsApp features to streamline user interactions
                </li>
                <li className="feed-item feed-item-danger">
                  Create tailored messaging campaigns with ease
                </li>
              </ul>
              {/* <button className="btn btn-primary mt-4">
                Get Started with Integration
              </button> */}
            </div>
            <div className="col-md-5 col-lg-6mb-3 mb-md-0">
              <img
                src="../assets/images/captures/api.png"
                alt="Send an SMS"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="container section-1">
        <div className="row align-items-center justify-content-center">
          <h1 className="text-center">They Trust Us</h1>
          <ImageList
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexWrap: "nowrap",
            }}
            variant="standard"
          >
            {logoData.map((item) => (
              <ImageListItem key={item.img} sx={{ width: 150, height: 100 }}>
                <img
                  srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      </section>

      <div className="section-1 head-bg position-relative">
        <div className="container center">
          <div className="row justify-content-center">
            <h3 className="text-center mb-3">We'd love to hear from you</h3>
            <p className="fs-14 text-center">
              Our support team is happy to assist you regarding any technical
              query.
            </p>
            <div className="col-md-4 col-lg-3">
              <div className="card stretch stretch-full">
                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                  <UilChatBubbleUser className="text-black" />
                  <h6>Chat to Developers</h6>
                  <p className="text-center">
                    Our team is happy to assist you regarding any API query.
                  </p>
                  <span className="text-black fw-bold text-decoration-underline mt-auto">
                    info@nufisms.com
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="card stretch stretch-full">
                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                  <UilComments className="text-black" />
                  <h6>Chat to Support</h6>
                  <p className="text-center">
                    Get in touch with our friendly team.
                  </p>
                  <span className="text-black fw-bold text-decoration-underline mt-auto">
                    support@nufisms.com
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="card stretch stretch-full">
                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                  <UilMapMarker className="text-black" />
                  <h6>Visit Us</h6>
                  <p className="text-center">Visit our office Digitwace</p>
                  <Link
                    to={"https://digitwace.com/"}
                    className="text-black fw-bold text-decoration-underline mt-auto"
                  >
                    digiwace.com
                  </Link>
                </div>
              </div>
            </div>
            {/* <button className="btn btn-light mt-3 mx-auto col-md-3">
              contact us
            </button> */}
          </div>
        </div>
      </div>

      <Footer onNavigate={scrollToSection} />
    </div>
  );
};

export default HomePage;
