import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  UilAngleRightB,
  UilAngleDown,
  UilSunset,
  UilArrowRight,
  UilAlignLeft,
  UilMoon,
  UilBell,
  UilWallet,
  UilUser,
  UilSignout,
} from "@iconscout/react-unicons";
import "../styles/Navigation.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { menuItems } from "../data/MenuItems";
import { useAuth } from "./auth/AuthContext";
import { fetchBalance } from "../redux/slices/accountSlice";
import { Skeleton } from "@mui/material";

type NavigationProps = {
  isMiniMenu: boolean;
  onToggleMenu: () => void;
};

const Navigation = ({ isMiniMenu, onToggleMenu }: NavigationProps) => {
  const location = useLocation();
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState<number | null>(null);
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const dispatch = useDispatch<AppDispatch>();
  const { balance, isLoading, errors } = useSelector(
    (state: RootState) => state.account
  );
  // const { login } = useAuth();
  // login(currentUser?.role ?? "user");
  const firstLetter =
    currentUser && currentUser.firstname ? currentUser.firstname.charAt(0) : "";

  const toggleSubMenu = (index: number) => {
    setOpenSubMenuIndex(openSubMenuIndex === index ? null : index);
  };

  const isActive = (path: string) => {
    return location.pathname === `/${path}` || location.pathname === path;
  };

  const isSubMenuActive = (subMenuItems: { path: string }[]) => {
    return subMenuItems.some((subItem) =>
      location.pathname.includes(subItem.path)
    );
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
  };

  const { isAllowed } = useAuth();

  const filteredMenuItems = menuItems.filter((item) => isAllowed(item.path));

  useEffect(() => {
    dispatch(fetchBalance());
  }, [dispatch]);

  return (
    <div className={`navigation ${isMiniMenu ? "mini-menu" : ""}`}>
      <nav className="sidebar bg-white">
        <div className="navbar-wrapper">
          <div className="m-header">
            <Link className="b-brand mx-auto" to="/">
              {isMiniMenu ? (
                <img
                  src="../assets/images/nufiLogo.png"
                  alt=""
                  className="logo logo-sm"
                  style={{ width: "50px" }}
                />
              ) : (
                <img
                  src="../assets/images/dark.png"
                  alt=""
                  className="logo logo-lg"
                  style={{ height: "70px" }}
                />
              )}
            </Link>
          </div>
          <div className="navbar-content">
            <ul className="nxl-navbar list-group">
              <li className="nxl-item nxl-caption fw-bold">
                <label className="text-primary fs-10">NAVIGATION</label>
              </li>
              {filteredMenuItems.map((menuItem, index) => (
                <li
                  key={index}
                  className={`nxl-item nxl-hasmenu ${
                    isActive(menuItem.path) ||
                    isSubMenuActive(menuItem.subMenu || [])
                      ? "active"
                      : ""
                  }`}
                >
                  {menuItem.subMenu ? (
                    <div
                      className={`nxl-link cursor-pointer ${
                        isActive(menuItem.path) ||
                        isSubMenuActive(menuItem.subMenu || [])
                          ? "active"
                          : ""
                      }`}
                      onClick={() => toggleSubMenu(index)}
                    >
                      <span className="nxl-micon">{menuItem.icon}</span>
                      <span className="nxl-mtext">{menuItem.label}</span>
                      <span className="nxl-arrow float-right">
                        {openSubMenuIndex === index ? (
                          <UilAngleDown />
                        ) : (
                          <UilAngleRightB />
                        )}
                      </span>
                    </div>
                  ) : (
                    <Link
                      to={menuItem.path}
                      className={`nxl-link ${
                        isActive(menuItem.path) ? "active" : ""
                      }`}
                    >
                      <span className="nxl-micon">{menuItem.icon}</span>
                      <span className="nxl-mtext">{menuItem.label}</span>
                    </Link>
                  )}
                  {menuItem.subMenu && openSubMenuIndex === index && (
                    <ul
                      className={`nxl-submenu ${isMiniMenu ? "d-none" : ""} ${
                        openSubMenuIndex === index ? "open" : ""
                      }`}
                    >
                      {menuItem.subMenu.map((subItem, subIndex) => (
                        <li
                          key={subIndex}
                          className={`nxl-item ${
                            isActive(subItem.path) ? "active" : ``
                          }`}
                        >
                          {subItem.path.startsWith("http") ? (
                            <Link
                              className="nxl-link"
                              to={subItem.path}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {subItem.label}
                            </Link>
                          ) : (
                            <Link
                              className={`nxl-link ${
                                isActive(subItem.path) ? "active" : ""
                              }`}
                              to={subItem.path}
                            >
                              {subItem.label}
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
            <div className="card text-center">
              <div className="card-body">
                <UilSunset />
                <h6 className="mt-4 text-dark fw-bolder">Stay Connected</h6>
                <p className="fs-11 my-3 text-dark">
                  Flexible integration of professional SMS
                </p>
                <Link
                  to="send-bulk"
                  className="btn btn-primary text-dark w-100"
                >
                  Send Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <header className="custom-header position-fixed header end-0 border bg-white d-flex align-items-center justify-content-between">
        <div className="menu-toggle text-black" onClick={onToggleMenu}>
          {isMiniMenu ? <UilArrowRight /> : <UilAlignLeft />}
        </div>
        <div
          className={`d-flex gap-3 align-items-center ${
            !isMiniMenu && "left-header"
          }`}
        >
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <Link
              to={`/settings?item=${"account-balance"}`}
              className="text-muted"
            >
              Balance :
            </Link>
            {isLoading ? (
              <Skeleton
                variant="text"
                style={{ width: "100px" }}
                sx={{ fontSize: "1rem" }}
              />
            ) : (
              <h5 className="m-0 text-primary">
                $ {Number(balance)?.toFixed(2) || "0.00"}
              </h5>
            )}
          </div>
          {/* <div className="avatar-image avatar-sm">
            <img
              src="https://getnexel.netlify.app/assets/vendors/img/flags/1x1/us.svg"
              alt=""
              className="img-fluid"
            />
          </div>
          <UilMoon /> */}
          <UilBell />
          <div className="dropdown nxl-h-item pe-2">
            <button
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              className="bg-transparent border-0"
            >
              <div
                style={{ height: "35px", width: "35px" }}
                className="border border-gray-2 rounded-circle bg-nf-secondary d-flex justify-content-center align-items-center"
              >
                <h5 className="text-center text-white my-auto">
                  {firstLetter}
                </h5>
              </div>
            </button>
            <div className="dropdown-menu dropdown-menu-end nxl-h-dropdown nxl-user-dropdown">
              <div className="dropdown-header">
                <div className="d-flex align-items-center">
                  <div>
                    <h6 className="text-dark mb-0">
                      {currentUser?.firstname} {currentUser?.lastname}
                    </h6>
                    <span className="fs-12 fw-medium text-muted">
                      {currentUser?.email}
                    </span>
                  </div>
                </div>
              </div>

              <Link
                to={`/settings?item=${"profile-details"}`}
                className="dropdown-item"
              >
                <UilUser />
                <span>Profile Details</span>
              </Link>
              <Link
                to={`/settings?item=${"account-balance"}`}
                className="dropdown-item"
              >
                <UilWallet />
                <span>Account Balance</span>
              </Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/auth" onClick={handleLogout}>
                <UilSignout />
                <span>Logout</span>
              </Link>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navigation;
