import axiosInstance from "./axiosInstance";

const createSession = async (data: { phoneNumber: string }) => {
  const response = await axiosInstance.post("session/create", data);
  return response.data;
};

const sendFromFile = async (formData: FormData) => {
  const response = await axiosInstance.post(
    "whatsapp-message/send-message",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

const initSession = async (phoneNumber: string) => {
  const response = await axiosInstance.get(
    `whatsapp-message/initialize/${phoneNumber}`
  );
  return response.data;
};

const sendMessageToContacts = async (data: any) => {
  const response = await axiosInstance.post(
    "whatsapp-message/send-message",
    data
  );
  return response.data;
};

const extractWhatsappGroups = async (
  phoneNumber: string,
  params: {
    page: number;
    pageSize: number;
  }
) => {
  const response = await axiosInstance.get(
    `whatsapp-message/groups/${phoneNumber}`,
    { params }
  );
  return response.data;
};
const extractContactsFromGroup = async (data: {
  phoneNumber: string;
  groupIds: string[];
}) => {
  const response = await axiosInstance.post(
    `whatsapp-message/contacts/extract`,
    data
  );
  return response.data;
};

export const whatsappService = {
  createSession,
  sendFromFile,
  initSession,
  sendMessageToContacts,
  extractWhatsappGroups,
  extractContactsFromGroup,
};
