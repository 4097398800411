import React, { useEffect } from "react";
import { UilEllipsisV, UilSave, UilFileAlt } from "@iconscout/react-unicons";
import Creatable from "react-select/creatable";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { useDispatch } from "react-redux";
import { getAllContacts } from "../../redux/slices/contactSlice";
import { Contact } from "../../models/Contacts";
import { AnyAction } from "@reduxjs/toolkit";
import { SendMessage } from "../../models/SMS";
import { sendMessage } from "../../redux/slices/smsSlice";

type Props = {};
export interface OptionType {
  value: string;
  label: string;
}

export const customStyles = {
  option: (defaultStyles: any, state: { isSelected: any }) => ({
    ...defaultStyles,
    color: state.isSelected ? "#ff488b" : "#283c50",
    backgroundColor: "transparent",
  }),

  control: (defaultStyles: any) => ({
    ...defaultStyles,
    border: "1px solid #a0a0a0",
    boxShadow: "none",
  }),
  multiValue: (defaultStyles: any) => ({
    ...defaultStyles,
    backgroundColor: "#e5edfa",
  }),
  multiValueLabel: (defaultStyles: any) => ({
    ...defaultStyles,
    color: "#0052cc",
  }),
  multiValueRemove: (defaultStyles: any) => ({
    ...defaultStyles,
    color: "#0052cc",
    ":hover": {
      backgroundColor: "#0052cc",
      color: "white",
    },
  }),
};

const SingleSMS = (props: Props) => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleSend = async (values: any) => {
    setSubmitting(true);

    const contactLookup: Record<string, Contact> = contacts.reduce(
      (acc: Record<string, Contact>, contact) => {
        if (contact.phone) {
          acc[contact.phone] = contact;
        }
        return acc;
      },
      {} as Record<string, Contact>
    );

    const formatContact = values.to.map((option: OptionType): Contact => {
      const contact = contactLookup[option.value];
      return {
        firstname: contact?.firstname || "",
        lastname: contact?.lastname || "",
        email: contact?.email || "",
        phone: option.value,
      };
    });

    try {
      const data: SendMessage = {
        sender: values.title,
        smsContent: values.message,
        contactsJson: formatContact,
        contacts: [],
      };

      await dispatch(sendMessage({ data }) as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }

          toast.success("Request initiated!");
          formik.resetForm();
          formik.validateForm();
        })
        .catch((error: any) => {
          toast.error("Failed to send SMS", error.message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } catch (error) {
      toast.error("Oops an occured occured, try again later");
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      to: [] as OptionType[],
      message: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Required"),
      to: Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.string().required("Required"),
            label: Yup.string().required("Required"),
          })
        )
        .when("useUploadedContacts", {
          is: false,
          then: (schema) =>
            schema
              .min(1, "At least one recipient is required")
              .required("Required"),
        }),
      message: Yup.string()
        .max(128, "Message must be at most 128 characters")
        .required("Required"),
    }),
    onSubmit: (values) => {
      return handleSend(values);
    },
  });

  const transformContactsToOptions = (contacts: Contact[]): any[] => {
    return contacts
      .filter(
        (contact) => contact.firstname && contact.lastname && contact.phone
      ) // Filter valid contacts
      .map((contact) => ({
        value: contact.phone,
        label: `${contact.firstname} ${contact.lastname}`, // Add a space between firstname and lastname
      }));
  };

  const transformedContacts = transformContactsToOptions(contacts ?? []);

  useEffect(() => {
    formik.validateForm();
  }, []);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const res: any = await dispatch(
          getAllContacts() as unknown as AnyAction
        ).unwrap();
        if (res.status === -1) {
          setContacts([]);
        } else {
          setContacts(res);
        }
      } catch (err) {
        toast.error("Failed to fetch contacts: An error occurred.");
      }
    };

    fetchContacts();

    // Clean-up function is not necessary in this case
  }, [dispatch]);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container apps-container apps-email">
          <div className="nxl-content without-header nxl-full-content">
            <div className="main-content d-flex">
              <div
                className="content-area"
                data-scrollbar-target="#psScrollbarInit"
              >
                <div className="row m-3 mb-0">
                  <div className="mb-4 col-12 align-items-center justify-content-between">
                    <h5 className="fw-bold mb-0 me-4">
                      <span className="d-block mb-2">
                        Select Saved Contacts and send SMS easily
                      </span>
                      <span className="fs-12 fw-normal text-muted text-truncate-1-line">
                        Note that the created contacts here will be saved to
                        your contacts list.
                      </span>
                      <p className="text-primary m-0">
                        Important : When creating new contacts, add country code
                        before the number. Example : +237698765467
                      </p>
                    </h5>
                  </div>
                </div>
                <div
                  className="items-details"
                  data-scrollbar-target="#psScrollbarInit"
                >
                  <div className="items-details-footer mail-action-editor m-4 border border-top-0 rounded-3">
                    <div
                      className="p-0 ht-400 border-top position-relative editor-section"
                      data-scrollbar-target="#psScrollbarInit"
                    >
                      <div className="position-relative border-bottom">
                        <div className="px-3 w-100 d-flex align-items-center">
                          <input
                            className="form-control border-0 my-1 shadow-none"
                            type="text"
                            placeholder="From"
                            maxLength={11}
                            id="title"
                            value={formik.values.title}
                            onChange={(
                              e: React.FormEvent<HTMLInputElement>
                            ) => {
                              const value = e.currentTarget.value.replace(
                                /\/../g,
                                ""
                              );
                              formik.setFieldValue("title", value);
                            }}
                            onBlur={formik.handleBlur}
                            // {...formik.getFieldProps("title")}
                          />
                          <span className="text-muted mt-2 text-nowrap">
                            {formik.values.title.length} / 11 characters
                          </span>
                        </div>
                      </div>
                      <div className="position-relative border-bottom">
                        <div className="px-2 d-flex align-items-center">
                          <div className="p-0 w-100">
                            <Creatable<OptionType, true>
                              options={transformedContacts}
                              className="form-control border-0 text-dark"
                              placeholder="TO"
                              styles={customStyles}
                              required
                              id="to"
                              isMulti
                              name="to"
                              value={formik.values.to}
                              onChange={(option) =>
                                formik.setFieldValue("to", option)
                              }
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="h-100 border-0 px-3" id="mailEditor">
                        <textarea
                          id="message"
                          className="w-100 border border-bottom-0 border-top ht-200"
                          placeholder="Type a message..."
                          required
                          maxLength={128}
                          {...formik.getFieldProps("message")}
                        ></textarea>
                        <div className="text-muted mt-2">
                          {formik.values.message.length} / 128 characters
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 d-flex align-items-center justify-content-between border-top">
                      {/* <!--! BEGIN: [mail-editor-action-left] !--> */}
                      <div className="d-flex align-items-center gap-2">
                        <button
                          className="btn btn-primary"
                          style={{ zIndex: "1" }}
                          disabled={
                            submitting ||
                            !formik.values.message ||
                            !formik.isValid
                          }
                          onClick={() => {
                            formik.handleSubmit();
                          }}
                        >
                          {submitting && <span className="loader"></span>}
                          Send
                        </button>
                        <div className="dropdown d-none d-sm-block">
                          <button
                            data-bs-toggle="dropdown"
                            data-bs-offset="0, 0"
                            className="border-0 bg-transparent"
                          >
                            <span
                              className="btn btn-icon"
                              data-bs-toggle="tooltip"
                              data-bs-trigger="hover"
                              title="Pick Template"
                            >
                              <UilEllipsisV />
                            </span>
                          </button>
                          <div className="dropdown-menu wd-300">
                            <button className="dropdown-item">
                              <UilSave className="me-3" />
                              <span className="text-truncate-1-line">
                                Save as Template
                              </span>
                            </button>
                            <button className="dropdown-item">
                              <UilFileAlt className="me-3" />
                              <span>Manage Template</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default SingleSMS;
