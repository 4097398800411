import React, { useCallback, useEffect, useRef, useState } from "react";
import Creatable from "react-select/creatable";
import * as Yup from "yup";
import {
  UilSearch,
  UilPlus,
  UilAlignLeft,
  UilTimes,
  UilUsersAlt,
  UilEllipsisH,
  UilEditAlt,
  UilTrashAlt,
} from "@iconscout/react-unicons";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import {
  ContactModel,
  Contact,
  CreateGroup,
  ContactGroup,
} from "../../models/Contacts";
import {
  getAllContactGroups,
  addContactGroup,
} from "../../redux/slices/contactGroupSlice";
import { addContactsToGroup } from "../../redux/slices/contactSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { contactService } from "../../services/contactService";
import {
  parseCSVFile,
  parseExcelFile,
  parseTextFile,
} from "../../utils/fileParsers";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

interface OptionType {
  value: string;
  label: string;
}

const ContactsGroup: React.FC = () => {
  const dispatch:AppDispatch = useDispatch();
  const [isCreating, setCreating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState<number>(0);

  const groups = useSelector((state: RootState) => state.groups.contactGroups);
  const contacts = groups ? groups[selectedGroupIndex]?.contacts : [];
  const [notInGroup, setNotInGroup] = useState<ContactModel[]>([]);
  const [uploadedContacts, setUploadedContacts] = useState<Contact[]>([]);
  const [selctedContacts, setSelectedContacts] = useState<OptionType[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const fetchGroups = useCallback(async () => {
    try {
      setIsFetching(true);
      const result = await dispatch(
        getAllContactGroups() 
      ).unwrap();
      if (result.length === 0) {
        return;
      }
    } catch (err) {
      toast.error("Failed to fetch contact groups.");
    } finally {
      setIsFetching(false);
    }
  }, [dispatch]);

  const fetchContactsNotInGroup = useCallback(async () => {
    if (!groups) return;
    try {
      const group_code = groups[selectedGroupIndex].group_code;
      const res = await contactService.getAllContactNotInGroups(group_code);
      setNotInGroup(res.data);
    } catch (error) {
      setNotInGroup([]);
    }
  }, [groups, selectedGroupIndex]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  useEffect(() => {
    fetchContactsNotInGroup();
  }, [selectedGroupIndex, fetchContactsNotInGroup]);

  const handleGroupClick = (index: number) => {
    setSelectedGroupIndex(index);
  };

  const handleCreateGroup = async (values: CreateGroup) => {
    setCreating(true);
    try {
      await dispatch(addContactGroup(values)).unwrap();
      setCreating(false);
      toast.success("Group Created Successfully!");
      formik.resetForm();
      fetchGroups();
    } catch (err) {
      setCreating(false);
      toast.error("Failed to create group: An error occurred.");
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    let data: Contact[] = [];

    if (file.type === "text/csv") {
      data = await parseCSVFile(file);
    } else if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel"
    ) {
      data = await parseExcelFile(file);
    } else if (file.type === "text/plain") {
      data = await parseTextFile(file);
    }
    setUploadedContacts(data);
  };

  const handleAddContacts = async () => {
    // Create a lookup object to map phone numbers to contact details
  const contactLookup: Record<string, Contact> = notInGroup.reduce(
    (acc: Record<string, Contact>, contact) => {
      if (contact.phone) {
        acc[contact.phone] = contact; // Add contact to the lookup if it has a phone number
      }
      return acc;
    },
    {} as Record<string, Contact>
  );
    if (!groups) return;
    setCreating(true);
    const groupCode = groups[selectedGroupIndex].group_code;
    const formattedContacts = selctedContacts.map((option: OptionType) => {
      const contact = contactLookup[option.value];
      return {
        firstname: contact?.firstname || "",
        lastname: contact?.lastname || "",
        email: contact?.email || "",
        phone: option.value,
      };
    });
    const data =
      uploadedContacts.length > 0 ? uploadedContacts : formattedContacts;

    dispatch(
      addContactsToGroup({
        contacts: data,
        groupCode: groupCode,
      }) 
    )
      .unwrap()
      .then((res: { message: string; status: number }) => {
        toast.success(
          "Contacts Saved Successfully! You can preview them in your contacts page",
          { duration: 6000 }
        );
        setUploadedContacts([]);
        setSelectedContacts([]);
        fetchGroups();
        fetchContactsNotInGroup();
      })
      .catch((error: any) => {
        toast.error("Failed to save contacts", error);
      })
      .finally(() => {
        setCreating(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      group_name: "",
      description: "",
    },
    validationSchema: Yup.object({
      group_name: Yup.string().required("Required"),
    }),
    onSubmit: handleCreateGroup,
  });

  const transformContactsToOptions = (
    contacts: ContactModel[]
  ): OptionType[] => {
    return contacts.map((contact) => ({
      value: contact.phone,
      label: `${contact.firstname} ${contact.lastname} - ${contact.phone}`,
    }));
  };

  const options: OptionType[] = transformContactsToOptions(notInGroup);

  const uploadedContatcsCount = uploadedContacts.length;
  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container apps-container apps-notes">
          <div className="nxl-content without-header nxl-full-content">
            <div className="main-content d-flex">
              <div
                className="content-sidebar content-sidebar-md"
                data-scrollbar-target="#psScrollbarInit"
              >
                <div className="content-sidebar-header bg-white sticky-top hstack justify-content-between">
                  <h4 className="fw-bolder mb-0">Groups</h4>
                  <div className="app-sidebar-close-trigger d-flex cursor-pointer a-div">
                    <UilTimes />
                  </div>
                </div>
                <div className="content-sidebar-header">
                  <button
                    className="btn btn-primary w-100"
                    id="add-notes"
                    data-bs-toggle="modal"
                    data-bs-target="#addgroupmodal"
                  >
                    <UilPlus className="feather-plus me-2 small svg" />
                    <span>Add Group</span>
                  </button>
                </div>
                <div className="content-sidebar-body">
                  {isFetching ? (
                    <div className="p-3">
                      <Stack spacing={1}>
                        <Skeleton variant="rectangular" height={60} />
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" height={60} />
                        <Skeleton variant="rectangular" height={60} />
                      </Stack>
                    </div>
                  ) : (
                    <ul className="nav d-flex flex-column nxl-content-sidebar-item">
                      {groups &&
                        groups?.length !== 0 &&
                        groups.map((group: ContactGroup, index) => (
                          <li
                            key={index}
                            className="nav-item"
                            onClick={() => handleGroupClick(index)}
                          >
                            <div
                              className={
                                "nav-link note-link border-0 cursor-pointer a-div" +
                                (selectedGroupIndex === index ? " active" : "")
                              }
                              id="all-category"
                            >
                              <span>{group.group_name}</span>
                            </div>
                          </li>
                        ))}

                      {groups && groups?.length === 0 && (
                        <div className="d-flex flex-column align-items-center p-3">
                          <div
                            className="bg-primary-light mb-2 border rounded-circle d-flex justify-content-center align-items-center"
                            style={{ height: "40px", width: "40px" }}
                          >
                            <UilUsersAlt className="text-primary" />
                          </div>
                          The groups you created will appear here
                        </div>
                      )}
                    </ul>
                  )}
                </div>
              </div>
              <div
                className="content-area"
                data-scrollbar-target="#psScrollbarInit"
              >
                <div className="content-area-header sticky-top">
                  <div className="page-header-left d-flex align-items-center gap-2">
                    <div className="app-sidebar-open-trigger me-2 cursor-pointer a-div">
                      <UilAlignLeft />
                    </div>
                  </div>
                  <div className="page-header-right ms-auto">
                    <div className="hstack gap-2">
                      <div className="hstack">
                        <div className="search-form-open-toggle">
                          <div
                            className="avatar-text avatar-md"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            title="Search"
                          >
                            <UilSearch className="small-svg" />
                          </div>
                        </div>
                      </div>
                      <div className="content-sidebar-header">
                        <div
                          className="btn btn-primary w-100"
                          id="add-notes"
                          data-bs-toggle="modal"
                          data-bs-target="#addgroupmodal"
                        >
                          <UilPlus />
                          <span>Add Group</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-area-body pb-0">
                  {selectedGroupIndex !== null &&
                    groups &&
                    groups?.length !== 0 && (
                      <div className="d-flex justify-content-between">
                        <div>
                          <h5>
                            Details for {groups[selectedGroupIndex].group_name}
                          </h5>
                          <div>{groups[selectedGroupIndex].description}</div>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          Add contacts to group
                        </button>
                      </div>
                    )}
                  <div className="card stretch stretch-full mt-2">
                    <div className="card-body p-0">
                      {isFetching ? (
                        <div className="p-3">
                          <Stack spacing={1}>
                            <Skeleton variant="rectangular" height={60} />
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                            <Skeleton variant="rectangular" height={60} />
                            <Skeleton variant="rectangular" height={60} />
                          </Stack>
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <table
                            className="table table-hover"
                            id="customerList"
                          >
                            <thead>
                              <tr>
                                <th className="wd-30">
                                  <div className="btn-group mb-1">
                                    <div className="custom-control custom-checkbox ms-1">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="checkAllCustomer"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="checkAllCustomer"
                                      ></label>
                                    </div>
                                  </div>
                                </th>
                                <th>Firstname</th>
                                <th>Lastname</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th className="text-end">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {contacts &&
                                contacts.map((contact, index) => (
                                  <tr key={index} className="single-item">
                                    <td>
                                      <div className="item-checkbox ms-1">
                                        <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input checkbox"
                                            id="checkBox_1"
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="checkBox_1"
                                          ></label>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <a
                                        className="hstack gap-3"
                                        href="customers-view.html"
                                      >
                                        {/* <div className="avatar-image avatar-md">
                                    <img
                                      src="../assets/images/avatar/1.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div> */}
                                        <div>
                                          <span className="text-truncate-1-line">
                                            {contact.firstname || "---"}
                                          </span>
                                        </div>
                                      </a>
                                    </td>
                                    <td>
                                      <a
                                        className="hstack gap-3"
                                        href="customers-view.html"
                                      >
                                        <div>
                                          <span className="text-truncate-1-line">
                                            {contact.lastname || "---"}
                                          </span>
                                        </div>
                                      </a>
                                    </td>
                                    <td>
                                      <a href="send-single">
                                        {contact.email || "---"}
                                      </a>
                                    </td>
                                    <td>
                                      <a href="tel:">{contact.phone}</a>
                                    </td>
                                    <td>
                                      <div className="hstack gap-2 justify-content-end">
                                        {/* <a
                                          className="avatar-text avatar-md"
                                          href="customers-view.html"
                                        >
                                          <UilEye className="small-svg" />
                                        </a> */}
                                        <div className="dropdown">
                                          <button
                                            className="avatar-text avatar-md"
                                            data-bs-toggle="dropdown"
                                            data-bs-offset="0,21"
                                          >
                                            <UilEllipsisH className="small-svg" />
                                          </button>
                                          <ul className="dropdown-menu">
                                            <li>
                                              <button className="dropdown-item border-0">
                                                <UilEditAlt className="me-3" />
                                                <span>Edit</span>
                                              </button>
                                            </li>
                                            <li className="dropdown-divider"></li>
                                            <li>
                                              <button className="dropdown-item border-0">
                                                <UilTrashAlt className="me-3" />
                                                <span>Delete</span>
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {contacts?.length === 0 && (
                            <h5 className="text-center my-5">
                              Your contact list will appear here
                            </h5>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Contacts to Group
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p className="text-muted">
                  Select existing contacts or upload a file containing your
                  contacts
                </p>
                <form id="addnotesmodalTitle">
                  <div className="text-primary mb-1 fw-bold">
                    {uploadedContacts.length !== 0
                      ? "Note : Only uploaded contacts will be considered"
                      : ""}
                  </div>
                  <label htmlFor="select">Select Contacts</label>
                  <Creatable<OptionType, true>
                    options={options}
                    isMulti
                    id="select"
                    value={selctedContacts}
                    isDisabled={uploadedContacts.length !== 0}
                    onChange={(selectedContacts) => {
                      setSelectedContacts(selectedContacts as OptionType[]);
                    }}
                  />
                  <h5 className="my-3 text-center">OR</h5>
                  <label htmlFor="upload">Upload contacts</label>
                  <div className="d-flex border align-items-center">
                    <input
                      type="file"
                      name="upload"
                      id="upload"
                      className="p-2 w-100"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      accept=".csv, .xlsx, .xls, .txt"
                    />
                    <UilTimes
                      className="border me-1 cursor-pointer"
                      onClick={() => setUploadedContacts([])}
                    />
                  </div>
                  <div className="text-end">
                    {uploadedContatcsCount} contacts uploaded
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  disabled={
                    isCreating ||
                    (selctedContacts.length === 0 &&
                      uploadedContacts.length === 0)
                  }
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleAddContacts}
                  data-bs-dismiss="modal"
                >
                  {isCreating && <span className="loader"></span>}
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="addgroupmodal"
          tabIndex={-1}
          data-bs-keyboard="false"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalTitleId">
                  Add Group
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="notes-box">
                  <div className="notes-content">
                    <form
                      onSubmit={formik.handleSubmit}
                      id="addnotesmodalTitle"
                    >
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <div className="note-title">
                            <label className="form-label">Group Name</label>
                            <input
                              type="text"
                              id="group_name"
                              className="form-control"
                              minLength={25}
                              placeholder="Group Name"
                              {...formik.getFieldProps("group_name")}
                            />
                            {formik.touched.group_name &&
                            formik.errors.group_name ? (
                              <div className="text-danger">
                                {formik.errors.group_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="note-description">
                            <label className="form-label">
                              Group Description
                            </label>
                            <textarea
                              id="note-has-description"
                              className="form-control"
                              minLength={60}
                              placeholder="Description"
                              rows={5}
                              {...formik.getFieldProps("description")}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-danger" data-bs-dismiss="modal">
                  Discard
                </button>
                <button
                  id="btn-n-add"
                  className="btn btn-success"
                  disabled={!formik.isValid || isCreating}
                  data-bs-dismiss="modal"
                  onClick={() => formik.handleSubmit()}
                >
                  {isCreating && <span className="loader"></span>}
                  Add Group
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default ContactsGroup;
