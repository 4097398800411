import React from "react";
import { UilAlignRight } from "@iconscout/react-unicons";

type PageHeaderProps = {
  title: string;
};

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  return (
    <div className="page-header">
      <div className="page-header-left d-flex align-items-center">
        <div className="page-header-title">
          <h5 className="m-b-10">{title}</h5>
        </div>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="index-2.html">Home</a>
          </li>
          <li className="breadcrumb-item">Dashboard</li>
        </ul>
      </div>
      <div className="page-header-right ms-auto">
        <div className="page-header-right-items">
          <div className="d-flex d-md-none">
            <a
              href="javascript:void(0)"
              className="page-header-right-close-toggle"
            >
              <i className="feather-arrow-left me-2"></i>
              <span>Back</span>
            </a>
          </div>
          <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
            <div
              id="reportrange"
              className="reportrange-picker d-flex align-items-center"
            >
              <span className="reportrange-picker-field"></span>
            </div>
            {/* <div className="dropdown filter-dropdown">
              <a
                className="btn btn-md btn-light-brand"
                data-bs-toggle="dropdown"
                data-bs-offset="0, 10"
                data-bs-auto-close="outside"
              >
                <i className="feather-filter me-2"></i>
                <span>Filter</span>
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                <div className="dropdown-item">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="Role"
                    />
                    <label
                      className="custom-control-label c-pointer"
                      htmlFor="Role"
                    >
                      Role
                    </label>
                  </div>
                </div>
                <div className="dropdown-item">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="Team"
                    />
                    <label
                      className="custom-control-label c-pointer"
                      htmlFor="Team"
                    >
                      Team
                    </label>
                  </div>
                </div>
                <div className="dropdown-item">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="Email"
                    />
                    <label
                      className="custom-control-label c-pointer"
                      htmlFor="Email"
                    >
                      Email
                    </label>
                  </div>
                </div>
                <div className="dropdown-item">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="Member"
                    />
                    <label
                      className="custom-control-label c-pointer"
                      htmlFor="Member"
                    >
                      Member
                    </label>
                  </div>
                </div>
                <div className="dropdown-item">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="Recommendation"
                    />
                    <label
                      className="custom-control-label c-pointer"
                      htmlFor="Recommendation"
                    >
                      Recommendation
                    </label>
                  </div>
                </div>
                <div className="dropdown-divider"></div>
                <a href="javascript:void(0);" className="dropdown-item">
                  <i className="feather-plus me-3"></i>
                  <span>Create New</span>
                </a>
                <a href="javascript:void(0);" className="dropdown-item">
                  <i className="feather-filter me-3"></i>
                  <span>Manage Filter</span>
                </a>
              </div>
            </div> */}
          </div>
        </div>
        <div className="d-md-none d-flex align-items-center">
          <a
            href="javascript:void(0)"
            className="page-header-right-open-toggle"
          >
            <UilAlignRight className="fs-4" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
