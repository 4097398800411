import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Package } from "../../models/Package";
import { packageService } from "../../services/packageService";

export interface PackageState {
  packages: Package[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: PackageState = {
  packages: null,
  loading: false,
  error: null,
};

export const createPackage = createAsyncThunk<void, Package>(
  "package/create",
  async (data: Package, { dispatch }) => {
    const response = await packageService.createPackage(data);
    dispatch(getAllPackages());
    return response;
  }
);

export const getAllPackages = createAsyncThunk<Package[]>(
  "package/get",
  async () => {
    const response = await packageService.getPackages();
    return response.data;
  }
);

export const editPackage = createAsyncThunk<
  void,
  { data: Partial<Package>; code: string }
>("package/edit", async ({ data, code }, { dispatch }) => {
  const response = await packageService.editPackage(code, data);
  dispatch(getAllPackages());
  return response;
});

export const subscribePackage = createAsyncThunk<
  void,
  { packageId: number; userId: number }
>("package/edit", async ({ packageId, userId }) => {
  const response = await packageService.subscribePackage({ userId, packageId });
  return response;
});

export const packageSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPackage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPackage.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createPackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to create package";
      })
      .addCase(getAllPackages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAllPackages.fulfilled,
        (state, action: PayloadAction<Package[]>) => {
          state.loading = false;
          state.packages = action.payload;
          state.error = null;
        }
      )
      .addCase(getAllPackages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch packages";
      })
      .addCase(editPackage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editPackage.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(editPackage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to edit package";
      });
  },
});

export default packageSlice.reducer;
