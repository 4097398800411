import moment from "moment";
import React from "react";
import { Client } from "../../models/Client";

type SmsTransactionsProps = {
  clientData?: Client;
};

const SmsTransactions: React.FC<SmsTransactionsProps> = ({ clientData }) => {
  return (
    <div className="tab-pane fade" id="smsTab" role="tabpanel">
      <div className="payment-history">
        <div className="table-responsive">
          <table className="table mb-0">
            <thead>
              <tr className="border-top">
                <th>ID</th>
                <th>Total SMS Sent</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {clientData?.sentMessages &&
                clientData.sentMessages.map((sms: any, index) => (
                  <tr>
                    <td>{sms.id}</td>
                    <td>350</td>
                    <td>{moment(sms.timestamp).format("DD/MM/YYYY HH:mm")}</td>
                  </tr>
                ))}
              {clientData?.sentMessages &&
                clientData.sentMessages.length === 0 && (
                  <tr>
                    <td>No SMS Record</td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SmsTransactions;
